import React, {Dispatch, ReactElement, useState} from 'react';
import {hasLink} from '../../../utils/HateoasFunctions';
import {HasHateoasLinks} from '../../../types/HateoasLink';
import NavItem from './NavItem';
import {fetchRootLinks} from '../../../api/Root';
import {visibleItems} from '../NavigationItemsHelper';
import {NavItemDataType} from './NavItemDataType';
import {
    IconBriefcase2,
    IconBuildingCommunity, IconChevronLeft, IconChevronRight,
    IconTool,
    IconUser,
    IconUserDollar
} from '@tabler/icons-react';
import {useHeaderHeight} from '../../../pages/layout/Layout';
import {c} from '../../../utils/CssFunctions';

export const sidebarData: (links: HasHateoasLinks) => Array<NavItemDataType> = (links: HasHateoasLinks) => [
    {
        title: 'Werknemers',
        icon: IconBriefcase2,
        show: hasLink(links, 'employees'),
        url: 'employees',
    },
    {
        title: 'Techniekers',
        icon: IconTool,
        show: hasLink(links, 'technicians'),
        url: 'technicians',
    },
    {
        title: 'Klanten',
        icon: IconUserDollar,
        show: hasLink(links, 'customers'),
        url: 'customers',
    },
    {
        title: 'Gebruikers',
        icon: IconUser,
        show: hasLink(links, 'users'),
        url: 'users',
    },
    {
        title: 'Diensten',
        icon: IconBuildingCommunity,
        show: hasLink(links, 'schemes'),
        url: 'schemes',
    },
];


function Sidebar(): ReactElement {
    const [collapsed, setCollapsed]: [boolean, Dispatch<boolean>] = useState<boolean>(false);

    const rootLinks = fetchRootLinks();
    const { headerHeight } = useHeaderHeight();

    return (
        <header className='relative'>
            <ul className='sticky left-0 list-none flex flex-col shadow-md' style={{ height: `calc(100vh - ${headerHeight}px)`, top: `${headerHeight}px` }}>
                {visibleItems(sidebarData(rootLinks)).map((item, index) => <NavItem item={item} key={index} collapsed={collapsed} />)}
            </ul>
            <button className={c('hidden sm:block absolute top-0 -right-8 text-riptide-darker hover:text-riptide bg-white-darkest hover:bg-white border border-riptide-darker hover:border-riptide shadow-md px-0.5')}>
                {!collapsed && <IconChevronLeft size={28} onClick={() => setCollapsed(true)} />}
                {collapsed && <IconChevronRight size={28} onClick={() => setCollapsed(false)} />}
            </button>
        </header>
    )
}

export default Sidebar;