import React, {ReactElement, useEffect} from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import Layout from './pages/layout/Layout';
import Booking from './pages/Booking';
import Secretariat from './pages/Secretariat';
import Login from './pages/Login';
import {useAppDispatch, useAppSelector} from './store/hooks';
import {
    selectLoginUrl,
    setRedirectUrl
} from './store/reducers/AuthenticationSlice';
import RequireRootLink from './components/helpers/routing/RequireRootLink';
import AdminLayout from './pages/layout/AdminLayout';
import EmployeeOverview from './pages/admin/users/EmployeeOverview';
import {CustomMap} from './types/CustomMap';
import RequireAnyRootLink from './components/helpers/routing/RequireAnyRootLink';
import CustomerOverview from './pages/admin/users/CustomerOverview';
import TechnicianOverview from './pages/admin/users/TechnicianOverview';
import CustomerDetails from './pages/admin/users/CustomerDetails';
import EmployeeDetails from './pages/admin/users/EmployeeDetails';
import TechnicianDetails from './pages/admin/users/TechnicianDetails';
import UserDetails from './pages/admin/users/UserDetails';
import UserOverview from './pages/admin/users/UserOverview';
import {ADMIN_PANEL_PREFIX} from './utils/AdminPanelUrl';
import SchemeOverview from './pages/admin/SchemeOverview';
import SchemeDetails from './pages/admin/SchemeDetails';

type AdminRoute = {
    requiredLink: string;
    component: ReactElement;
};

function App(): ReactElement {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const loginUrl = useAppSelector(selectLoginUrl);

    useEffect(() => {
        if(location.pathname !== loginUrl) {
            dispatch(setRedirectUrl(location.pathname));
        }
    }, [dispatch, location.pathname, loginUrl]);

    const adminPathsWithRequiredRootLink: CustomMap<AdminRoute> = {
        'employees': {
            requiredLink: 'employees',
            component: <EmployeeOverview />,
        },
        'employees/:id': {
            requiredLink: 'employees',
            component: <EmployeeDetails />
        },
        'technicians': {
            requiredLink: 'technicians',
            component: <TechnicianOverview />
        },
        'technicians/:id': {
            requiredLink: 'technicians',
            component: <TechnicianDetails />
        },
        'customers': {
            requiredLink: 'customers',
            component: <CustomerOverview />
        },
        'customers/:id': {
            requiredLink: 'customers',
            component: <CustomerDetails />
        },
        'users': {
            requiredLink: 'users',
            component: <UserOverview />
        },
        'users/:id': {
            requiredLink: 'users',
            component: <UserDetails />
        },
        'schemes': {
            requiredLink: 'schemes',
            component: <SchemeOverview />
        },
        'schemes/:id': {
            requiredLink: 'schemes',
            component: <SchemeDetails />
        },
    }

    return (
        <Routes>
            <Route path="/" element={<Layout/>}>
                <Route index element={<Navigate to='booking' replace />}/>
                <Route path="booking" element={<RequireRootLink rootLink='create-customer-booking'><Booking/></RequireRootLink>}/>
                <Route path="secretariat" element={<RequireRootLink rootLink='create-secretariat-booking'><Secretariat/></RequireRootLink>}/>
                <Route path={ADMIN_PANEL_PREFIX.slice(1)} element={<RequireAnyRootLink rootLinks={Object.values(adminPathsWithRequiredRootLink).map(e => e.requiredLink)}><AdminLayout/></RequireAnyRootLink>}>
                    <Route index element={<Navigate to='employees' replace />} />
                    {Object.keys(adminPathsWithRequiredRootLink).map((key, index) =>
                        <Route key={index} path={key} element={<RequireRootLink rootLink={adminPathsWithRequiredRootLink[key].requiredLink}>{adminPathsWithRequiredRootLink[key].component}</RequireRootLink>}/>
                    )}
                </Route>
                <Route path="login" element={<Login/>}/>
                <Route path="profile" element={<RequireRootLink rootLink='profile'><p>Profiel</p></RequireRootLink>}/>
            </Route>
        </Routes>
    );
}

export default App;
