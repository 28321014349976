import React, {Dispatch, ReactElement, ReactNode, useEffect, useState} from 'react';
import {resolve} from '../../../utils/HateoasFunctions';
import {fetchRootLinks} from '../../../api/Root';
import {useQuery} from '@tanstack/react-query';
import {customerQueries} from '../../../api/Customers';
import {useLocation, useNavigate} from 'react-router-dom';
import {appendSearchParams} from '../../../api/HelperFunctions';
import {adminPanelApiUrlFromBrowserLink, adminPanelBrowserLinkFromApiUrl} from '../../../utils/AdminPanelUrl';
import {Customer} from '../../../model/User';
import AdminPageOverviewWrapper, {ElementColumnTemplate, ElementRowTemplate} from '../../../components/helpers/wrappers/AdminPageOverviewWrapper';

const DEFAULT_SORT = 'userData.email';

function CustomerOverview(): ReactElement {
    const location = useLocation();
    const navigate = useNavigate();

    const [apiUrl, setApiUrl]: [string | undefined, Dispatch<string>] = useState<string>();

    const rootLinks = fetchRootLinks();
    useEffect(() => {
        // TODO: customers link moet aangeven dat er een sort kan zijn??
        if (!location.search.includes('sort=')) {
            const searchParams = new URLSearchParams({sort: DEFAULT_SORT});
            const apiUrl: URL = resolve(rootLinks, 'customers');
            appendSearchParams(apiUrl, searchParams);

            navigate(adminPanelBrowserLinkFromApiUrl(apiUrl), {replace: true});
        }
    });

    const { data: customerOverview, isLoading: isLoadingCustomers} = useQuery(customerQueries.findAllFromUrl(apiUrl));

    useEffect(() => {
        setApiUrl(adminPanelApiUrlFromBrowserLink(location));
    }, [location.search]);

    const overview = {
        pagedOverview: customerOverview,
        datakey: 'customers',
    };

    // TODO: table loader
    if(isLoadingCustomers) {
        return (
            <h3>Loading...</h3>
        );
    }


    return (
        <AdminPageOverviewWrapper title='Klanten' overview={overview} elementListTemplate={elementListTemplate} />
    );
}

const elementListTemplate: (customer: Customer) => ReactNode = (customer: Customer) => {
    return (
        <ElementRowTemplate key={customer.id} element={customer} >
            <ElementColumnTemplate label="Email" value={customer.email || ''} width="w-full md:w-7/12 lg:w-5/12 flex flex-col"/>
            <ElementColumnTemplate label="Voornaam" value={customer.firstName} width="hidden md:flex md:w-2/12 lg:w-2/12 flex-col"/>
            <ElementColumnTemplate label="Achternaam" value={customer.lastName} width="hidden md:flex md:w-3/12 lg:w-2/12 flex-col"/>
            <ElementColumnTemplate label="TelefoonNummer" value={customer.phoneNumber} width="hidden lg:flex lg:w-3/12 flex-col"/>
        </ElementRowTemplate>
    );
};

export default CustomerOverview;