import {Action, combineReducers, configureStore, Reducer} from '@reduxjs/toolkit';
import secretariatBookingReducer from './reducers/SecretariatBookingSlice';
import customerBookingWizardReducer from './reducers/CustomerBookingWizardSlice';
import authenticationReducer from './reducers/AuthenticationSlice';

const combinedReducers = combineReducers({
    authentication: authenticationReducer,
    secretariatBooking: secretariatBookingReducer,
    customerBookingWizard: customerBookingWizardReducer,
});

const rootReducer: Reducer = (state: RootState, action: Action) => {
    // TODO: nieuwe action, logoutAtAPI doen we niet meer!
    if(action.type === 'authentication/LOGOUT') {
        state = {} as RootState;
    }
    return combinedReducers(state, action);
}

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof combinedReducers>;