import {follow} from './HelperFunctions';
import {Customer, User} from '../model/User';
import {hasLink} from '../utils/HateoasFunctions';
import {HasHateoasLinks} from '../types/HateoasLink';
import {Dispatch, SetStateAction} from 'react';
import {PagedOverview} from '../types/Overview';
import type {UseQueryOptions} from '@tanstack/react-query/src/types';
import axios from 'axios';

export const customerQueries = {
    findFromCurrentUser: (currentUser?: User): UseQueryOptions<Customer> => ({
        queryKey: ['current-user', 'customer-data'],
        queryFn: () => follow<Customer>(currentUser!, 'customer-data'),
        enabled: !!currentUser && hasLink(currentUser, 'customer-data'),
    }),
    findByEmail: (rootLinks: HasHateoasLinks, email: string, shouldFetch: boolean, setShouldFetch: Dispatch<SetStateAction<boolean>>): UseQueryOptions<Customer> => ({
        queryKey: ['customer-with-email', email],
        queryFn: () => {
            setShouldFetch(false)
            return follow<Customer>(rootLinks, 'customer-with-email', {urlParams: {email}})
        },
        enabled: shouldFetch && hasLink(rootLinks, 'customer-with-email'),
        retry: false,
    }),
    findById: (url: string): UseQueryOptions<Customer> => ({
        queryKey: ['customers', url],
        queryFn: () => axios.get<Customer>(url).then(response => response.data),
        retry: false,
    }),
    findAllFromUrl: (url?: string): UseQueryOptions<PagedOverview<Customer>> => {
        return ({
            queryKey: ['customers', url],
            queryFn: () => axios.get<PagedOverview<Customer>>(url!).then(response => response.data),
            retry: false,
            enabled: !!url
        });
    },
    findFromUser: (user?: User): UseQueryOptions<Customer> => ({
        queryKey: ['customers', user?.id],
        queryFn: () => follow<Customer>(user!, 'customer-data'),
        enabled: !!user && hasLink(user, 'customer-data'),
    }),
};