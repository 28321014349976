import axios, {AxiosRequestConfig} from 'axios';
import {HasHateoasLinks} from '../types/HateoasLink';
import {CustomMap} from '../types/CustomMap';
import {hasLink, resolve} from '../utils/HateoasFunctions';
import {fetchRootLinks} from './Root';

export type OptionalFollowParams = {
    urlParams?: CustomMap<string>;
    searchParams?: URLSearchParams;
    config?: AxiosRequestConfig;
}

type OptionalFollowMutationParams = OptionalFollowParams & {
    body?: object;
}

export async function follow<T>(object: HasHateoasLinks, rel: string, optionalParams?: OptionalFollowParams): Promise<T> {
    const url = resolve(object, rel, optionalParams?.urlParams);
    appendSearchParams(url, optionalParams?.searchParams);
    return (await axios.get<T>(url.href, optionalParams?.config)).data;
}

export async function followPost<T>(object: HasHateoasLinks, rel: string, optionalParams?: OptionalFollowMutationParams): Promise<T> {
    const url = resolve(object, rel, optionalParams?.urlParams);
    appendSearchParams(url, optionalParams?.searchParams);
    return (await axios.post<T>(url.href, optionalParams?.body, optionalParams?.config)).data;
}

export async function followPostForm<T>(object: HasHateoasLinks, rel: string, optionalParams?: OptionalFollowMutationParams): Promise<T> {
    const url = resolve(object, rel, optionalParams?.urlParams);
    appendSearchParams(url, optionalParams?.searchParams);
    return (await axios.postForm<T>(url.href, optionalParams?.body, optionalParams?.config)).data;
}

export async function followPut<T>(object: HasHateoasLinks, rel: string, optionalParams?: OptionalFollowMutationParams): Promise<T> {
    const url = resolve(object, rel, optionalParams?.urlParams);
    appendSearchParams(url, optionalParams?.searchParams);
    return (await axios.put<T>(url.href, optionalParams?.body, optionalParams?.config)).data;
}

export async function followPatch<T>(object: HasHateoasLinks, rel: string, optionalParams?: OptionalFollowMutationParams): Promise<T> {
    const url = resolve(object, rel, optionalParams?.urlParams);
    appendSearchParams(url, optionalParams?.searchParams);
    return (await axios.patch<T>(url.href, optionalParams?.body, optionalParams?.config)).data;
}

export function appendSearchParams(url: URL, urlSearchParams?: URLSearchParams): void {
    urlSearchParams?.forEach((value: string, key: string) => {
        url.searchParams.append(key, value);
    });
}

export const isAuthenticated: () => boolean = () => hasLink(fetchRootLinks(), 'logout')
