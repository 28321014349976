import React, {ReactElement} from 'react';
import GoogleLogin from './oauth2/GoogleLogin';

function OAuth2(): ReactElement {
    return (
        <div className="flex flex-col mt-5">
            <GoogleLogin />
        </div>
    )
}

export default OAuth2;