import React, {ReactElement, ReactNode} from 'react';
import {c} from '../../../utils/CssFunctions';

interface PropType {
    children: ReactNode;
    colorStyles: string;
    alwaysVisible?: boolean;
    topLevelClassNames?: string;
}

function AnimatedUnderline(props: PropType): ReactElement {
    return (
        <span className={c('group relative inline-block', props.topLevelClassNames || '')}>
            {props.children}
            <span className="absolute bottom-0 left-0 w-full flex justify-left">
                <span
                    className={c('h-[3px] -mb-0.5 bg-riptide group-hover:w-full', props.alwaysVisible ? 'w-full' : 'w-0 transition-width duration-300')}
                />
            </span>
        </span>
    );
}

export default AnimatedUnderline;