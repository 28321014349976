import React, {ReactElement} from 'react';
import {IconCheck, IconX} from '@tabler/icons-react';

type PropType = {
    complete: boolean;
}

function StepCompleted(props: PropType): ReactElement {
    if(props.complete) {
        return <IconCheck stroke={3} />
    }
    return <IconX stroke={3} className='text-error'/>
}

export default StepCompleted;