import React, {ReactElement} from 'react';
import AdminPageDetailWrapper from '../../../components/helpers/wrappers/AdminPageDetailWrapper';
import {hasLink} from '../../../utils/HateoasFunctions';
import UserSection from './sections/UserSection';
import CustomerSection from './sections/CustomerSection';
import EmployeeSection from './sections/EmployeeSection';
import TechnicianSection from './sections/TechnicianSection';
import {useQuery} from '@tanstack/react-query';
import {userQueries} from '../../../api/Users';
import {customerQueries} from '../../../api/Customers';
import {employeeQueries} from '../../../api/Employees';
import {technicianQueries} from '../../../api/Technicians';
import {adminPanelApiUrlFromBrowserLink} from '../../../utils/AdminPanelUrl';
import {useLocation} from 'react-router-dom';

function UserDetails(): ReactElement {
    const location = useLocation();
    const { data: userDetail, isLoading: isLoadingUserDetail} = useQuery(userQueries.findById(adminPanelApiUrlFromBrowserLink(location)));

    const { data: customerDetail, isLoading: isLoadingCustomerDetail} = useQuery(customerQueries.findFromUser(userDetail));
    const { data: employeeDetail, isLoading: isLoadingEmployeeDetail} = useQuery(employeeQueries.findFromUser(userDetail));
    const { data: technicianDetail, isLoading: isLoadingTechnicianDetail} = useQuery(technicianQueries.findFromUser(userDetail));

    return (
        <AdminPageDetailWrapper title='Gebruikers-gegevens'>
            <UserSection isLoading={isLoadingUserDetail} user={userDetail} />
            {
                userDetail && hasLink(userDetail, 'customer-data') &&
              <CustomerSection isLoading={isLoadingCustomerDetail} customer={customerDetail} />
            }
            {
                userDetail && hasLink(userDetail, 'employee-data') &&
              <EmployeeSection isLoading={isLoadingEmployeeDetail} employee={employeeDetail} />
            }
            {
                userDetail && hasLink(userDetail, 'technician-data') &&
              <TechnicianSection isLoading={isLoadingTechnicianDetail} technician={technicianDetail} />
            }
        </AdminPageDetailWrapper>
    );
}

export default UserDetails;