import {follow} from './HelperFunctions';
import {PagedOverview} from '../types/Overview';
import {Employee, User} from '../model/User';
import {hasLink} from '../utils/HateoasFunctions';
import axios from 'axios';
import {UseQueryOptions} from '@tanstack/react-query/src/types';

export const employeeQueries = {
    findAllFromUrl: (url?: string): UseQueryOptions<PagedOverview<Employee>> => {
        return ({
            queryKey: ['employees', url],
            queryFn: () => axios.get<PagedOverview<Employee>>(url!).then(response => response.data),
            retry: false,
            enabled: !!url
        });
    },
    findById: (url: string): UseQueryOptions<Employee>  => ({
        queryKey: ['employees', url],
        queryFn: () => axios.get<Employee>(url).then(response => response.data),
        retry: false,
    }),
    findFromUser: (user?: User): UseQueryOptions<Employee>  => ({
        queryKey: ['employees', user?.id],
        queryFn: () => follow<Employee>(user!, 'employee-data'),
        enabled: !!user && hasLink(user, 'employee-data'),
    }),
};