import React, {MutableRefObject, ReactElement, useEffect, useRef, useState} from 'react';
import {NavItemDataType} from '../NavItemDataType';
import {c} from '../../../../utils/CssFunctions';
import AnimatedUnderline from '../../../helpers/wrappers/AnimatedUnderline';
import {Link, useLocation} from 'react-router-dom';
import DropDown from './DropDown';
import {IconChevronDown} from '@tabler/icons-react';

type NavItemPropType = {
    item: NavItemDataType;
};

function NavItem(props: NavItemPropType): ReactElement {
    if(props.item.subItems) {
        return (
            <li className="flex relative">
                <NavDropdownItem item={props.item} />
            </li>
        );
    }

    return (
        <li className="flex">
            <NavActionItem item={props.item} />
        </li>
    );
}

function NavActionItem(props: NavItemPropType): ReactElement {
    const location = useLocation();
    const isSelected = location.pathname.slice('/'.length).startsWith(props.item.url || '');

    if(props.item.onClick) {
        return (
            <span onClick={() => props.item.onClick!()}
                  className={c('font-semibold cursor-pointer uppercase text-sm mx-2.5 p-2.5 decoration-[3px] underline-offset-4 transition-all duration-300', isSelected ? 'text-riptide underline' : 'text-riptide-darker hover:underline hover:text-riptide')}>
                <AnimatedUnderline colorStyles='riptide' alwaysVisible={isSelected}>
                    {props.item.title}
                </AnimatedUnderline>
            </span>
        )
    }

    return (
        <Link to={props.item.url!}
              className={c('font-semibold uppercase text-sm mx-2.5 p-2.5 decoration-[3px] underline-offset-4 transition-all duration-300', isSelected ? 'text-riptide underline' : 'text-riptide-darker hover:underline hover:text-riptide')}>
            <AnimatedUnderline colorStyles='riptide' alwaysVisible={isSelected}>
                {props.item.title}
            </AnimatedUnderline>
        </Link>
    )
}

function NavDropdownItem(props: NavItemPropType): ReactElement {
    const [dropdown, setDropdown] = useState(false);
    const ref: MutableRefObject<any> = useRef<any>();

    useEffect(() => {
        const handler = (event: MouseEvent | TouchEvent) => {
            if (dropdown && ref.current && !ref.current.contains(event.target)) {
                setTimeout(() => {
                    setDropdown(false);
                }, 300)
            }
        };
        document.addEventListener('mousedown', handler);
        document.addEventListener('touchstart', handler);
        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', handler);
            document.removeEventListener('touchstart', handler);
        };
    }, [dropdown]);

    return (
        <>
            <span ref={ref} onClick={() => setDropdown(!dropdown)}
                  className={c('font-semibold cursor-pointer uppercase text-sm mx-2.5 p-2.5 decoration-[3px] underline-offset-4 transition-all duration-300', dropdown ? 'text-riptide underline' : 'text-riptide-darker hover:underline hover:text-riptide')}>
                <AnimatedUnderline colorStyles='riptide' alwaysVisible={dropdown}>
                    <span className='flex flex-row items-center'>
                        {props.item.title}
                        <IconChevronDown size={18} className={c('transition-transform', dropdown ? '-rotate-180' : '')}/>
                    </span>
                </AnimatedUnderline>
            </span>
            <DropDown dropdown={dropdown} items={props.item.subItems!} />
        </>
    )
}

export default NavItem;