import React, {ReactElement} from 'react';
import {NavItemDataType} from '../NavItemDataType';
import {c} from '../../../../utils/CssFunctions';
import {Link, useLocation} from 'react-router-dom';
import {visibleItems} from '../../NavigationItemsHelper';

type DropdownPropType = {
    dropdown: boolean;
    items: Array<NavItemDataType>;
    hideOverlayFunction: () => void;
};

type DropdownItemPropType = {
    item: NavItemDataType;
    hideOverlayFunction: () => void;
};

function DropDown(props: DropdownPropType): ReactElement {
    return (
        <ul className={c('bg-darkgray-lightest px-5 list-none', props.dropdown ? 'block' : 'hidden')}>
            {visibleItems(props.items!).map((item, index) => <DropdownItem key={index} item={item} hideOverlayFunction={props.hideOverlayFunction} />)}
        </ul>
    )
}

function DropdownItem(props: DropdownItemPropType): ReactElement {
    return (
        <li className='flex border-b last:border-b-0 border-riptide-darker'>
            <DropdownActionItem item={props.item} hideOverlayFunction={props.hideOverlayFunction} />
        </li>
    )
}

function DropdownActionItem(props: DropdownItemPropType): ReactElement {
    const location = useLocation();
    const isSelected = location.pathname === props.item.url;

    const onClick: () => void = () => {
        props.hideOverlayFunction();
        props.item.onClick && props.item.onClick();
    }

    if(props.item.onClick) {
        return (
            <span className={c('w-full cursor-pointer text-sm px-8 py-3 decoration-[3px] transition-all duration-300', isSelected ? 'text-riptide' : 'text-white hover:text-riptide')} onClick={() => onClick()}>
                {props.item.title}
            </span>
        )
    }

    return (
        <Link to={props.item.url!} className={c('w-full text-sm px-8 py-3 decoration-[3px] transition-all duration-300', isSelected ? 'text-riptide' : 'text-white hover:text-riptide')} onClick={() => onClick()}>
            {props.item.title}
        </Link>
    )
}

export default DropDown;

