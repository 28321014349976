import {follow} from './HelperFunctions';
import {hasLink} from '../utils/HateoasFunctions';
import {PagedOverview} from '../types/Overview';
import {Technician, User} from '../model/User';
import {UseQueryOptions} from '@tanstack/react-query/src/types';
import axios from 'axios';

export const technicianQueries = {
    findAllFromUrl: (url?: string): UseQueryOptions<PagedOverview<Technician>> => {
        return ({
            queryKey: ['technicians', url],
            queryFn: () => axios.get<PagedOverview<Technician>>(url!).then(response => response.data),
            retry: false,
            enabled: !!url
        });
    },
    findById: (url: string): UseQueryOptions<Technician> => ({
        queryKey: ['technicians', url],
        queryFn: () => axios.get<Technician>(url).then(response => response.data),
        retry: false,
    }),
    findFromUser: (user?: User): UseQueryOptions<Technician> => ({
        queryKey: ['technicians', user?.id],
        queryFn: () => follow<Technician>(user!, 'technician-data'),
        enabled: !!user && hasLink(user, 'technician-data'),
    }),
};