import React, {ReactElement, ReactNode} from 'react';
import {Link} from 'react-router-dom';
import {c} from '../../../utils/CssFunctions';

interface PropType {
    children?: ReactNode;
    icon?: ReactNode;
    to: string;
}

export function InternalLink(props: PropType): ReactElement {
    return (
        <Link to={props.to} className={c('underline text-riptide-darker hover:text-riptide transition-all duration-300 inline-block', props.icon && !props.children ? 'p-1.5 rounded-full hover:bg-white-darkest' : '')}>
            {props.children}{props.icon}
        </Link>
    );
}