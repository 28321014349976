import React, {ReactElement} from 'react';

type ElementComponentPropsType = {
    description: string;
    price: string;
};

function ElementComponent(props: ElementComponentPropsType): ReactElement {

    return (
        <div className="flex flex-row space-x-4 p-2 border-2 border-riptide-darker rounded">
            <p className="text-left">{props.description}</p>
            <p className="font-semibold text-right grow">{props.price}</p>
        </div>
    );
}

export default ElementComponent;