import React, {MutableRefObject, ReactElement, useEffect, useRef, useState} from 'react';
import AdminPageDetailWrapper from '../../components/helpers/wrappers/AdminPageDetailWrapper';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {schemeQueries} from '../../api/Schemes';
import {adminPanelApiUrlFromBrowserLink} from '../../utils/AdminPanelUrl';
import AdminSectionWrapper from '../../components/helpers/wrappers/AdminSectionWrapper';
import TextField from '../../components/helpers/forms/TextField';
import DropDown, {OptionType} from '../../components/helpers/forms/DropDown';
import Button from '../../components/helpers/forms/Button';
import {followPut} from '../../api/HelperFunctions';
import {Scheme} from '../../model/Scheme';
import {fetchRootLinks} from '../../api/Root';
import {choiceListQueries} from '../../api/ChoiceLists';
import Toggle from '../../components/helpers/forms/Toggle';
import {useLocation} from 'react-router-dom';

function SchemeDetails(): ReactElement {
    const queryClient = useQueryClient();
    const location = useLocation();

    const [editMode, setEditMode] = useState<boolean>(false);
    const submitEditReference: MutableRefObject<HTMLButtonElement | null> = useRef<HTMLButtonElement>(null);

    const [title, setTitle] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [hours, setHours] = useState<number>();
    const [minutes, setMinutes] = useState<number>();
    const [price, setPrice] = useState<number>();
    const [maxTravelTime, setMaxTravelTime] = useState<number>();
    const [securityClearance, setSecurityClearance] = useState<string>();
    const [active, setActive] = useState<boolean>();

    const { data: schemeDetail, isLoading: isLoadingSchemeDetail, dataUpdatedAt: fetchTimer,  } = useQuery(schemeQueries.findById(adminPanelApiUrlFromBrowserLink(location)));

    const rootLinks = fetchRootLinks();
    const { data: choiceLists } = useQuery(choiceListQueries.fetchChoiceLists(rootLinks));
    const { data: securityClearanceOverview, isLoading: isLoadingSecurityClearances} = useQuery(choiceListQueries.findSecurityClearanceChoices(choiceLists));

    const assignableSecurityClearances = securityClearanceOverview?._embedded.securityClearances || [];

    useEffect(() => {
        if(schemeDetail) {
            fillInInitialSchemeFields();
        }
    }, [schemeDetail, fetchTimer]);

    const fillInInitialSchemeFields: () => void = () => {
        setTitle(schemeDetail!.title);
        setDescription(schemeDetail!.description);
        setHours(schemeDetail!.duration.hours);
        setMinutes(schemeDetail!.duration.minutes);
        setPrice(schemeDetail!.price);
        setMaxTravelTime(schemeDetail!.maxTravelTime);
        setSecurityClearance(schemeDetail!.securityClearance);
        setActive(schemeDetail!.active);
    }

    const cancelEdit: () => void = () => {
        fillInInitialSchemeFields();
        setEditMode(false);
    }

    const securityClearanceOptions: Array<OptionType> = assignableSecurityClearances
        .map(securityClearance => {
            return {value: securityClearance.value, label: securityClearance.label}
        });

    const updateSchemeBody: () => object = () => {
        return {
            title,
            description,
            hours,
            minutes,
            price,
            securityClearance,
            maxTravelTime,
            active,
        };
    }

    const updateSchemeMutation = useMutation({
        mutationFn: () => followPut<Scheme>(schemeDetail!, 'update', {body: updateSchemeBody()}),
        onSuccess: (data) => {
            queryClient.invalidateQueries({queryKey: ['schemes']})
                .then(() => queryClient.setQueryData(['schemes', data.id], data));
            setEditMode(false);
        },
    });

    return (
        <AdminPageDetailWrapper title='Dienst gegevens'>
            <AdminSectionWrapper title='Algemene gegevens' isLoading={isLoadingSchemeDetail} editMode={editMode} setEditMode={setEditMode} >
                <TextField width='w-full lg:w-1/3' label='Titel' name='title' value={title || ''}
                           disabled={!editMode} required onChange={event => setTitle(event.target.value)} />
                <TextField width='w-full lg:w-2/3' label='Omschrijving' name='description' value={description || ''}
                           disabled={!editMode} required onChange={event => setDescription(event.target.value)} />

                <TextField width='w-full sm:w-1/2 lg:w-1/4' label="Uren" name="hours"
                           value={hours?.toString() || ''} disabled={!editMode} required onChange={event => setHours(event.target.value)}/>
                <TextField width='w-full sm:w-1/2 lg:w-1/4' label="Minuten" name="minutes"
                           value={minutes?.toString() || ''} disabled={!editMode} required onChange={event => setMinutes(event.target.value)}/>
                <TextField width='w-full sm:w-1/2 lg:w-1/4' label="Prijs (in €)" name="price"
                           value={price?.toString() || ''} disabled={!editMode} required onChange={event => setPrice(event.target.value)}/>
                <TextField width='w-full sm:w-1/2 lg:w-1/4' label="Maximale rijtijd" name="maxTravelTime"
                           value={maxTravelTime?.toString() || ''} disabled={!editMode} onChange={event => setMaxTravelTime(event.target.value)}/>

                <DropDown width='w-full sm:w-3/4' label='Veiligheidsniveau' name='securityClearance'
                          value={securityClearance} options={securityClearanceOptions} disabled={!editMode} loading={isLoadingSecurityClearances} required
                          selectItem={item => setSecurityClearance(item.value)} nextFocusReference={submitEditReference}/>
                <Toggle width='w-full sm:w-1/4' onLabel='Actief' value={active === true} disabled={!editMode} onChange={() => setActive(!active)} />
                
                {
                    editMode &&
                  <div className='w-full flex justify-end'>
                    <Button onClick={() => cancelEdit()}>Annuleer</Button>
                    <Button reference={submitEditReference} onClick={() => updateSchemeMutation.mutate()}>Opslaan</Button>
                  </div>
                }
            </AdminSectionWrapper>
        </AdminPageDetailWrapper>
    )
}

export default SchemeDetails;