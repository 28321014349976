import {HasHateoasLinks} from '../types/HateoasLink';
import {useSuspenseQuery} from '@tanstack/react-query';
import axios from 'axios';
import {API_URL} from '../configuration/axios';

export const fetchRootLinks : () => HasHateoasLinks = () => {
    const { data: rootLinks } = useSuspenseQuery({
        queryKey: ['rootLinks'],
        queryFn: () => axios.get(API_URL).then(response => response.data),
        staleTime: Infinity,
    });
    return rootLinks;
};