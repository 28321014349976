import React, {ReactElement, ReactNode} from 'react';

interface PropType {
    children: ReactNode;
}

export function Title(props: PropType): ReactElement {
    return (
        <h1 className='text-2xl sm:text-3xl md:text-2xl lg:text-3xl text-riptide-darker font-bold'>
            {props.children}
        </h1>
    );
}