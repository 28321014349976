import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../Store';

// TODO: login url is een constante, hoort niet echt hier.... Is geen state
const LOGIN_URL = '/login';
const DEFAULT_REDIRECT_URL = '/';

type AuthenticationReducerStateType = {
    loginUrl: string;
    redirectUrl: string;
};

const initialState: AuthenticationReducerStateType = {
    loginUrl: LOGIN_URL,
    redirectUrl: DEFAULT_REDIRECT_URL,
};

export const logout = {
    type: 'authentication/LOGOUT',
};

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        setRedirectUrl: (state, action: PayloadAction<string>) => {
            state.redirectUrl = action.payload;
        },
    },
});

export const  {setRedirectUrl} = authenticationSlice.actions;

export const selectLoginUrl = (state: RootState) => state.authentication.loginUrl;
export const selectRedirectUrl = (state: RootState) => state.authentication.redirectUrl;

export default authenticationSlice.reducer