export const debounce = (delay: number, func: any) => {
    let timerId: NodeJS.Timeout | null;
    return function (...args: Array<any>) {
        if (timerId) {
            clearTimeout(timerId);
        }
        timerId = setTimeout(() => {
            func(...args);
            timerId = null;
        }, delay);
    };
};