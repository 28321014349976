import Header from '../../components/navigation/header/Header';
import {Outlet, useOutletContext} from 'react-router-dom';
import React, {Dispatch, ReactElement, useState} from 'react';

type ContextType = {
    headerHeight: number
};

function Layout(): ReactElement {
    const [headerHeight, setHeaderHeight]: [number, Dispatch<number>] = useState<number>(0);

    return (
        <div className='bg-white'>
            <Header setHeaderHeight={setHeaderHeight}/>
            <Outlet context={{ headerHeight } satisfies ContextType} />
        </div>
    );
}

export function useHeaderHeight() {
    return useOutletContext<ContextType>();
}

export default Layout;