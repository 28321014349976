import {HasHateoasLinks, HateoasLink} from '../types/HateoasLink';
import {CustomMap} from '../types/CustomMap';

type OperatorType = {
    operator: string;
    separator: string;
    named: boolean;
    ifEmpty: boolean;
};

const EXPANSION_REGEX = /(\{)[^{]*(})/g;

const DEFAULT_OPERATOR: OperatorType = {
    operator: '', separator: ',', named: false, ifEmpty: false,
};

const OPERATORS: Array<OperatorType> = [{operator: '#', separator: ',', named: false, ifEmpty: false}, {
    operator: '.', separator: '.', named: false, ifEmpty: false,
}, {operator: '/', separator: '/', named: false, ifEmpty: false}, {
    operator: ';', separator: ';', named: true, ifEmpty: false,
}, {operator: '?', separator: '&', named: true, ifEmpty: true}, {
    operator: '&', separator: '&', named: true, ifEmpty: true,
}];

export const resolve: (object: HasHateoasLinks, linkName: string, params?: CustomMap<string>) => URL = (object: HasHateoasLinks, linkName: string, params: CustomMap<string> = {}) => {
    if(!linkName) {
        throw new Error('No link name was passed to hal link resolver!');
    }
    return new URL(resolveLinksObject(object, linkName, params));
};

export const hasLink: (object: HasHateoasLinks, linkName: string) => boolean = (object: HasHateoasLinks, linkName: string) => {
    return !!(object._links && object._links[linkName]);
}

const resolveLinksObject: (object: HasHateoasLinks, linkName: string, params: CustomMap<string>) => string = (object: HasHateoasLinks, linkName: string, params: CustomMap<string>) => {
    const links = object._links;
    if(links && links[linkName]) {
        return resolveNamedLink(links[linkName], params);
    }
    throw new Error('Link name not found!');
};

const resolveNamedLink: (link: HateoasLink, params: CustomMap<string>) => string = (link: HateoasLink, params: CustomMap<string>) => {
    if(link.templated) {
        return resolveTemplatedLink(link, params)
    }
    return link.href;
};

const resolveTemplatedLink: (link: HateoasLink, params: CustomMap<string>) => string = (link: HateoasLink, params: CustomMap<string>) => {
    const groups = link.href.match(EXPANSION_REGEX)
    if(!groups) {
        throw new Error('Link should be templated, but does not contain placeholders!');
    }
    return groups.reduce((returnLink, str) => {
        // Sliced surrounding '{' and '}'
        const slicedStr = str.slice(1, -1);
        const matchedOperator = OPERATORS.find((operator) => slicedStr.startsWith(operator.operator));
        const operator = matchedOperator || DEFAULT_OPERATOR;

        if(!matchedOperator) {
            return returnLink.replace(`{${slicedStr}}`, translateArgumentsWithOperator(slicedStr, params, operator));
        }
        const argumentList = translateArgumentsWithOperator(slicedStr.slice(1), params, operator);
        return returnLink.replace(`{${slicedStr}}`, `${argumentList.length > 0 ? operator.operator : ''}${argumentList}`);
    }, link.href)
};

const translateArgumentsWithOperator: (str: string, params: CustomMap<string>, operator: OperatorType) => string = (str: string, params: CustomMap<string>, operator: OperatorType) => {
    return str.split(',')
        .filter(varName => containsParam(params, varName, operator))
        .map(varName => getValueFor(params, varName, operator))
        .join(operator.separator);
};

const containsParam: (params: CustomMap<string>, varName: string, operator: OperatorType) => boolean = (params: CustomMap<string>, varName: string, operator: OperatorType) => {
    if (operator.ifEmpty) {
        return Boolean(params && typeof params[varName] !== 'undefined');
    }
    return Boolean(params && params[varName]);
};

const getValueFor: (params: CustomMap<string>, varName: string, operator: OperatorType) => string = (params: CustomMap<string>, varName: string, operator: OperatorType) => {
    return new Array<string>().concat(params[varName])
        .reduce((acc, el) => acc.concat(getNamedOperatorIfNeeded(operator, varName, el)), new Array<string>())
        .join(operator.separator)
};

const getNamedOperatorIfNeeded: (operator: OperatorType, varName: string, el: string) => string = (operator: OperatorType, varName: string, el: string) => {
    if (operator.named) {
        return `${varName}=${el}`;
    }
    return el;
};