import React, {ReactElement} from 'react';
import AdminPageDetailWrapper from '../../../components/helpers/wrappers/AdminPageDetailWrapper';
import {hasLink} from '../../../utils/HateoasFunctions';
import UserSection from './sections/UserSection';
import CustomerSection from './sections/CustomerSection';
import EmployeeSection from './sections/EmployeeSection';
import TechnicianSection from './sections/TechnicianSection';
import {useQuery} from '@tanstack/react-query';
import {customerQueries} from '../../../api/Customers';
import {employeeQueries} from '../../../api/Employees';
import {technicianQueries} from '../../../api/Technicians';
import {adminPanelApiUrlFromBrowserLink} from '../../../utils/AdminPanelUrl';
import {useLocation} from 'react-router-dom';

function EmployeeDetails(): ReactElement {
    const location = useLocation();
    const { data: employeeDetail, isLoading: isLoadingEmployeeDetail} = useQuery(employeeQueries.findById(adminPanelApiUrlFromBrowserLink(location)));

    const { data: customerDetail, isLoading: isLoadingCustomerDetail} = useQuery(customerQueries.findFromUser(employeeDetail));
    const { data: technicianDetail, isLoading: isLoadingTechnicianDetail} = useQuery(technicianQueries.findFromUser(employeeDetail));

    return (
        <AdminPageDetailWrapper title='Gebruikers-gegevens'>
            <UserSection isLoading={isLoadingEmployeeDetail} user={employeeDetail} />
            {
                employeeDetail && hasLink(employeeDetail, 'customer-data') &&
                <CustomerSection isLoading={isLoadingCustomerDetail} customer={customerDetail} />
            }
            <EmployeeSection isLoading={isLoadingEmployeeDetail} employee={employeeDetail} />
            {
                employeeDetail && hasLink(employeeDetail, 'technician-data') &&
              <TechnicianSection isLoading={isLoadingTechnicianDetail} technician={technicianDetail} />
            }
        </AdminPageDetailWrapper>
    );
}

export default EmployeeDetails;