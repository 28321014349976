import {Scheme} from '../model/Scheme';
import type {UseQueryOptions} from '@tanstack/react-query/src/types';
import {PagedOverview} from '../types/Overview';
import axios from 'axios';

export const schemeQueries = {
    findAllFromUrl: (url?: string): UseQueryOptions<PagedOverview<Scheme>> => {
        return ({
            queryKey: ['schemes', url],
            queryFn: () => axios.get<PagedOverview<Scheme>>(url!).then(response => response.data),
            retry: false,
            enabled: !!url
        });
    },
    findById: (url: string): UseQueryOptions<Scheme> => ({
        queryKey: ['schemes', url],
        queryFn: () => axios.get<Scheme>(url).then(response => response.data),
        retry: false,
    })
};