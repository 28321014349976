import React, {ReactElement} from 'react';
import Wizard from '../components/booking/wizard/Wizard';
import SchemeSelector from '../components/booking/scheme/SchemeSelector';
import ExtraServicesSelector from '../components/booking/extra-services/ExtraServicesSelector';
import Calendar from '../components/booking/calendar/Calendar';
import UserInfo from '../components/booking/user-info/UserInfo';
import Overview from '../components/booking/overview/Overview';
import VisitingAddressInfo from '../components/booking/address/VisitingAddressInfo';
import CardLayout from '../components/helpers/wrappers/CardLayout';

function Booking(): ReactElement {
    return (
        <CardLayout>
            <Wizard/>
            <UserInfo/>
            <VisitingAddressInfo/>
            <SchemeSelector/>
            <ExtraServicesSelector/>
            <Calendar/>
            <Overview/>
        </CardLayout>
    );
}

export default Booking;