import React, {ReactElement} from 'react';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import ExtraServiceComponent from './ExtraServiceComponent';
import ContentWrapper from '../../helpers/wrappers/ContentWrapper';
import {
    addExtraServiceChoice,
    completeExtraServicesStep, removeExtraServiceChoice,
    selectExtraServiceChoices,
    selectExtraServicesStep
} from '../../../store/reducers/CustomerBookingWizardSlice';
import {fetchRootLinks} from '../../../api/Root';
import {useQuery} from '@tanstack/react-query';
import {choiceListQueries} from '../../../api/ChoiceLists';

function ExtraServicesSelector(): ReactElement | null {
    const dispatch = useAppDispatch();

    const rootLinks = fetchRootLinks();
    const { data: choiceLists } = useQuery(choiceListQueries.fetchChoiceLists(rootLinks));
    const { data: extraServiceOverview, isLoading: isLoadingExtraServices} = useQuery(choiceListQueries.findCustomerBookingExtraServiceChoices(choiceLists));

    const extraServices = extraServiceOverview?._embedded.customerBookingExtraServices || [];

    const extraServicesSelectorStepVisibilityState = useAppSelector(selectExtraServicesStep);
    const selectedExtraServices = useAppSelector(selectExtraServiceChoices);

    if(!extraServicesSelectorStepVisibilityState.isActive) {
        return null;
    }

    return (
        <ContentWrapper title="Extra diensten" contentLoading={isLoadingExtraServices} isActive={extraServicesSelectorStepVisibilityState.isActive} nextButtonDisabled={false} onNextButtonClick={() => dispatch(completeExtraServicesStep())}>
            {extraServices?.map(extraService => <ExtraServiceComponent key={extraService.id} extraService={extraService} checked={selectedExtraServices.includes(extraService)} removeExtraService={() => dispatch(removeExtraServiceChoice(extraService))} addExtraService={() => dispatch(addExtraServiceChoice(extraService))} />)}
        </ContentWrapper>
    );
}

export default ExtraServicesSelector;