import React, {Dispatch, ReactElement, ReactNode, useEffect, useState} from 'react';
import {resolve} from '../../../utils/HateoasFunctions';
import {fetchRootLinks} from '../../../api/Root';
import {useQuery} from '@tanstack/react-query';
import {userQueries} from '../../../api/Users';
import {useLocation, useNavigate} from 'react-router-dom';
import {appendSearchParams} from '../../../api/HelperFunctions';
import {adminPanelApiUrlFromBrowserLink, adminPanelBrowserLinkFromApiUrl} from '../../../utils/AdminPanelUrl';
import AdminPageOverviewWrapper, {
    ElementColumnTemplate,
    ElementRowTemplate
} from '../../../components/helpers/wrappers/AdminPageOverviewWrapper';
import {User} from '../../../model/User';

const DEFAULT_SORT = 'email';

function UserOverview(): ReactElement {
    const location = useLocation();
    const navigate = useNavigate();

    const [apiUrl, setApiUrl]: [string | undefined, Dispatch<string>] = useState<string>();

    const rootLinks = fetchRootLinks();
    useEffect(() => {
        // TODO: users link moet aangeven dat er een sort kan zijn??
        if (!location.search.includes('sort=')) {
            const searchParams = new URLSearchParams({sort: DEFAULT_SORT});
            const apiUrl: URL = resolve(rootLinks, 'users');
            appendSearchParams(apiUrl, searchParams);

            navigate(adminPanelBrowserLinkFromApiUrl(apiUrl), {replace: true});
        }
    });

    const { data: userOverview, isLoading: isLoadingUsers} = useQuery(userQueries.findAllFromUrl(apiUrl));

    useEffect(() => {
        setApiUrl(adminPanelApiUrlFromBrowserLink(location));
    }, [location.search]);

    const overview = {
        pagedOverview: userOverview,
        datakey: 'users',
    };

    // TODO: table loader
    if(isLoadingUsers) {
        return (
            <h3>Loading...</h3>
        );
    }

    return (
        <AdminPageOverviewWrapper title='Gebruikers' overview={overview} elementListTemplate={elementListTemplate} />
    );
}

const elementListTemplate: (user: User) => ReactNode = (user: User) => {
    return (
        <ElementRowTemplate key={user.id} element={user} >
            <ElementColumnTemplate label="Email" value={user.email || ''} width="w-full md:w-7/12 flex flex-col"/>
            <ElementColumnTemplate label="Voornaam" value={user.firstName} width="hidden md:flex md:w-2/12 flex-col"/>
            <ElementColumnTemplate label="Achternaam" value={user.lastName} width="hidden md:flex md:w-3/12 flex-col"/>
        </ElementRowTemplate>
    );
};

export default UserOverview;