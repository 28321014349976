import React, {ReactElement} from 'react';
import SubTitle from '../../helpers/text/Subtitle';
import {useAppSelector} from '../../../store/hooks';
import {selectExtraServiceChoices, selectSchemeChoice} from '../../../store/reducers/SecretariatBookingSlice';
import ElementHeader from '../../booking/overview/ElementHeader';
import ElementComponent from '../../booking/overview/ElementComponent';

function MaxTravelTime(): ReactElement {

    const selectedExtraServices = useAppSelector(selectExtraServiceChoices);
    const selectedScheme = useAppSelector(selectSchemeChoice) || {description: '', price: 0};

    return (
        <div className='space-y-2 pb-2 sm:pb-4 md:pb-6'>
            <SubTitle>Overzicht</SubTitle>

            <ElementHeader title="Afspraak"/>
            <ElementComponent description={selectedScheme.description} price={'€' + selectedScheme.price} />

            {selectedExtraServices.length > 0 && <br />}
            {selectedExtraServices.length > 0 && <ElementHeader title="Extra's"/>}
            {selectedExtraServices.map(extraService => <ElementComponent key={extraService.id} description={extraService.title} price={'€' + extraService.price} />)}

            <br />
            <ElementHeader title="Prijs"/>
            <ElementComponent description="Totale prijs (inclusief BTW)" price={'€' + (selectedScheme.price + selectedExtraServices.map(extraService => extraService.price).reduce((a, b) => a + b, 0))} />
        </div>
    );
}

export default MaxTravelTime;