import {Slot} from '../model/Slot';

export const slotAsText: (slot: Slot) => string = (slot: Slot) => {
    return slot.start + ' - ' + slot.end;
}

export const minutesToHoursAndMinutes: (val: number) => string = (val: number) => {
    const hours = Math.floor(val / 60);
    const minutes = val % 60;
    if(hours > 0) {
        if(minutes > 0) {
            return `${hours}u ${minutes}m`;
        }
        return `${hours}u`;
    }
    return `${minutes}m`;
}