import React, {MutableRefObject, ReactElement, ReactNode, useState} from 'react';
import {Title} from '../text/Title';
import {PagedOverview, PaginationType} from '../../../types/Overview';
import Button from '../forms/Button';
import {hasLink, resolve} from '../../../utils/HateoasFunctions';
import SubTitle from '../text/Subtitle';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {followPost} from '../../../api/HelperFunctions';
import {adminPanelBrowserLinkFromApiUrl} from '../../../utils/AdminPanelUrl';
import {Link} from 'react-router-dom';
import {IconChevronLeft, IconChevronRight, IconEye} from '@tabler/icons-react';
import DropDown from '../forms/DropDown';
import {c} from '../../../utils/CssFunctions';
import {InternalLink} from '../text/InternalLink';
import {HasHateoasLinks} from '../../../types/HateoasLink';
import {HasId} from '../../../model/HasId';

export type CreateElementType = {
    buttonText: string;
    form: ReactNode;
    submitButtonReference: MutableRefObject<HTMLButtonElement | null>,
    body: () => object;
    resetForm: () => void;
};

type ContentWrapperPropsType<T> = {
    title: string;
    overview: {
        pagedOverview: PagedOverview<T> | undefined;
        datakey: string;
    };
    elementListTemplate: (element: T) => ReactNode;
    createElement?: CreateElementType
}

// TODO: iets rond header om dit ook generiek te maken? -> of listview tof zoals het nu is?
// TODO: oude Admin page wrapper opkuisen en vervangen door deze
// TODO: uitbreiden met filters, sortering

function AdminPageOverviewWrapper<T>(props: ContentWrapperPropsType<T>): ReactElement {
    const queryClient = useQueryClient();

    const [createOpen, setCreateOpen] = useState(false);

    const cancelCreation: () => void = () => {
        setCreateOpen(false);
        props.createElement!.resetForm();
    }

    const pagedOverview = props.overview.pagedOverview;
    const data: Array<T> = props.overview.pagedOverview?._embedded[props.overview.datakey] || [];

    const createElementMutation = useMutation({
        mutationFn: () => followPost<T>(props.overview.pagedOverview!, 'create', {body: props.createElement?.body()}),
        onSuccess: () => {
            setCreateOpen(false);
            props.createElement!.resetForm();
            return queryClient.invalidateQueries({queryKey: [props.overview.datakey]});
        }
    })

    return (
        <div className='flex flex-col w-full items-center p-5 md:p-8 space-y-5'>
            <div className='flex flex-row w-full justify-between'>
                <Title>{props.title}</Title>
                {pagedOverview && hasLink(pagedOverview, 'create') && props.createElement && !createOpen &&
                    <Button onClick={() => setCreateOpen(true)}>{props.createElement.buttonText}</Button>}
                {createOpen && <Button onClick={() => cancelCreation()}>Annuleren</Button>}
            </div>

            {createOpen && props.createElement &&
                <div className='w-full p-5'>
                    <SubTitle>{props.createElement.buttonText}</SubTitle>
                    {props.createElement.form}
                    <Button reference={props.createElement.submitButtonReference}
                            onClick={() => createElementMutation.mutate()}
                        /* TODO: enkel wanneer loading disabled, hier moeten later validatie errors bij komen en loading={props.buttonLoading} */
                            right>Voeg toe</Button>
                </div>
            }

            {props.overview.pagedOverview &&
                <PaginationHeader pagedOverview={props.overview.pagedOverview} datakey={props.overview.datakey}/>}

            <ol className='space-y-1 w-full'>
                {data.map(element => props.elementListTemplate(element))}
            </ol>

            {props.overview.pagedOverview && <Pagination paginationType={props.overview.pagedOverview}/>}
        </div>
    );
}

type ElementRowPropType = {
    element: HasId & HasHateoasLinks;
    children: ReactNode;
}

export const ElementRowTemplate: (props: ElementRowPropType) => ReactElement = (props: ElementRowPropType) => {
    return (
        <li
            className='w-full flex items-center px-2 py-2 border border-riptide-darker text-riptide-darker shadow-md rounded-md'>
            {props.children}
            {hasLink(props.element, 'self') &&
                <span className='w-1/12 flex justify-end'><InternalLink to={`${props.element.id}`}
                                                                        icon={<IconEye/>}/></span>}
        </li>
    );
};

type ColumnTemplatePropType = {
    label: string;
    value: string;
    width: string;
};

export const ElementColumnTemplate: (props: ColumnTemplatePropType) => ReactElement = (props: ColumnTemplatePropType) => {
    return (
            <div className={props.width}>
                <span className='text-gray-lighter text-xs'>{props.label}</span>
                <span>{props.value}</span>
            </div>
    );
}

type PaginationHeaderPropsType<T> = {
    pagedOverview: PagedOverview<T>;
    datakey: string;
};

const possiblePageSizes: Array<number> = [5, 10, 20, 50];

function PaginationHeader<T>(props: PaginationHeaderPropsType<T>): ReactElement | null {
    const page = props.pagedOverview.page;
    const amountOnPage = props.pagedOverview._embedded[props.datakey].length;

    // TODO: page size in stellen mogelijk maken
    return (
        <div className="flex w-full items-center justify-between">
            <p className="text-sm text-riptide-darker">
                <span className="font-semibold">{(page.number * page.size) + 1}</span>-<span
                className="font-semibold">{((page.number * page.size) + amountOnPage)}</span> van <span
                className="font-semibold">{page.totalElements}</span>
            </p>
            <DropDown width="w-1/2 sm:w-1/3 md:w-1/4" label="Aantal elementen" name="rows" value={page.size.toString()}
                      selectItem={() => {
                      }} options={possiblePageSizes.map(size => {
                return {label: size.toString(), value: size.toString()};
            })} disabled/>
        </div>
    );
}

type PaginationPropsType = {
    paginationType: PaginationType;
};

function Pagination(props: PaginationPropsType): ReactElement | null {
    if (props.paginationType.page.totalPages <= 1) {
        return null;
    }

    const followLink: (linkName: string) => string = (linkName: string) => {
        return adminPanelBrowserLinkFromApiUrl(resolve(props.paginationType, linkName));
    }

    const currentPage = props.paginationType.page.number + 1;
    const amountOfPages = props.paginationType.page.totalPages;

    return (
        <div
            className="flex items-center justify-between bg-white-darker rounded-md shadow-md w-full px-2 py-2 border border-riptide-darker">
            <div className="flex w-full justify-between sm:hidden">
                <div>
                    {
                        hasLink(props.paginationType, 'prev') &&
                        <Link to={followLink('prev')}
                              className="relative inline-flex items-center rounded-md border border-riptide-darker bg-white px-4 py-2 text-sm font-semibold text-riptide-darker hover:bg-white-darkest">Vorige <IconChevronLeft/></Link>
                    }
                </div>
                <div>
                    {
                        hasLink(props.paginationType, 'next') &&
                        <Link to={followLink('next')}
                              className="relative inline-flex items-center rounded-md border border-riptide-darker bg-white px-4 py-2 text-sm font-semibold text-riptide-darker hover:bg-white-darkest">Volgende <IconChevronRight/></Link>
                    }
                </div>
            </div>
            <div className="hidden sm:flex w-full items-center justify-end">
                {
                    hasLink(props.paginationType, 'prev') &&
                    <Page to={followLink('prev')} first><IconChevronLeft/> Vorige</Page>
                }

                {
                    hasLink(props.paginationType, 'first') && currentPage > 1 && <Page to={followLink('first')} first={!hasLink(props.paginationType, 'prev')}>{1}</Page>
                }

                {
                    currentPage-2 > 1 && <p>...</p>
                }
                {
                    hasLink(props.paginationType, 'prev') && currentPage-1 > 1 && <Page to={followLink('prev')}>{currentPage-1}</Page>
                }
                <Page to={followLink('self')} current first={!hasLink(props.paginationType, 'prev')} last={!hasLink(props.paginationType, 'next')}>{currentPage}</Page>
                {
                    hasLink(props.paginationType, 'next') && currentPage+1 < amountOfPages && <Page to={followLink('next')} last={!hasLink(props.paginationType, 'next')}>{currentPage+1}</Page>
                }
                {
                    currentPage+2 < amountOfPages && <p>...</p>
                }

                {
                    hasLink(props.paginationType, 'last') && currentPage < amountOfPages && <Page to={followLink('last')} last={!hasLink(props.paginationType, 'next')}>{amountOfPages}</Page>
                }

                {
                    hasLink(props.paginationType, 'next') &&
                    <Page to={followLink('next')} last>Volgende <IconChevronRight/></Page>
                }
            </div>
        </div>
    );
}

type PagePropType = {
    children: ReactNode;
    current?: boolean;
    to: string;
    first?: boolean;
    last?: boolean;
}

function Page(props: PagePropType): ReactElement {
    return (
        <Link to={props.to}
              className={c('relative inline-flex items-center border-r border-y border-riptide-darker bg-white px-4 py-2 font-semibold text-riptide-darker hover:bg-white-darkest transition-all duration-300', props.current ? 'bg-white-darkest' : '', props.first ? 'border-l rounded-l-md' : '', props.last ? 'rounded-r-md' : '')}>
            {props.children}
        </Link>
    );
}

export default AdminPageOverviewWrapper;