import React, {ChangeEvent, ReactElement} from 'react';
import {c} from '../../../utils/CssFunctions';

interface Props {
    id: string;
    value: string;
    title: string;
    description?: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    width?: string;
    disabled?: boolean;
    checked: boolean;
}

function CheckBox(props: Props): ReactElement {
    return (
        <label
            className={c('flex p-2 my-2 rounded border bg-bayleaf/10 border-bayleaf/60', props.width || 'w-full', props.disabled ? 'opacity-40' : 'hover:cursor-pointer')}
            htmlFor={'checkbox-input-' + props.id}>
            <input value={props.value}
                   checked={props.checked}
                   className={c('w-4 h-4 mt-0.5 accent-riptide-darker bg-bayleaf/10 border-bayleaf/60', props.disabled ? '' : 'hover:cursor-pointer')}
                   disabled={props.disabled}
                   onChange={props.onChange}
                   id={'checkbox-input-' + props.id} name='checkbox-input' type='checkbox'/>
            <div className='ml-2'>
                <p className='text-sm font-medium text-black'>{props.title}</p>
                <p className='text-xs font-normal'>{props.description}</p>
            </div>
        </label>
    );
}

export default CheckBox;