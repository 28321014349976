import React, {ReactElement} from 'react';
import {Navigate} from 'react-router-dom';
import {useAppSelector} from '../../../store/hooks';
import {selectLoginUrl} from '../../../store/reducers/AuthenticationSlice';
import {hasLink} from '../../../utils/HateoasFunctions';
import {isAuthenticated} from '../../../api/HelperFunctions';
import {fetchRootLinks} from '../../../api/Root';

interface RequireRootLinkPropType {
    rootLink: string;
    children: ReactElement;
}

const DEFAULT_REDIRECT_URL = '/';

function RequireRootLink(props: RequireRootLinkPropType): ReactElement {
    const rootLinks = fetchRootLinks();
    const loginUrl = useAppSelector(selectLoginUrl);
    // TODO: ipv default redirect moeten we eigenlijk naar 404 of 403 redirecten
    const redirectUrl = isAuthenticated() ? DEFAULT_REDIRECT_URL : loginUrl;

    return hasLink(rootLinks, props.rootLink) ? props.children : <Navigate to={redirectUrl} />;
}

export default RequireRootLink;