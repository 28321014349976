import React, {ReactElement, ReactNode} from 'react';

interface PropType {
    children: ReactNode;
}

function SubTitle(props: PropType): ReactElement {
    return (
        <h2 className='flex items-center text-xl sm:text-2xl md:text-xl lg:text-2xl text-riptide-darker font-bold'>
            {props.children}
        </h2>
    );
}

export default SubTitle;