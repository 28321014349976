import React, {ChangeEvent, ReactElement} from 'react';
import {c} from '../../../utils/CssFunctions';

interface Props {
    onLabel: string;
    offLabel?: string;
    value: boolean;
    width?: string;
    disabled?: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

function Toggle(props: Props): ReactElement {

    const onChange: (event: ChangeEvent<HTMLInputElement>) => void = (event: ChangeEvent<HTMLInputElement>) => {
        if(!props.disabled) {
            props.onChange(event);
        }
    }

    return (
        <div className={c('inline-flex pb-2 pt-4 px-1 justify-center items-center space-x-3', props.width || 'w-full', props.disabled ? 'opacity-40' : '')}>
            {props.offLabel && <span className="text-sm font-medium">{props.offLabel}</span>}
            <label className={c(!props.disabled ? 'cursor-pointer' : '')}>
                <input type="checkbox" className="sr-only peer" checked={props.value} onChange={onChange} disabled={props.disabled}/>
                <div
                    className="relative w-11 h-6 bg-gray-lightest rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-riptide-darker"></div>
            </label>
            <span className="text-sm font-medium">{props.onLabel}</span>
        </div>
    );
}

export default Toggle;