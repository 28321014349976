import React, {ReactElement} from 'react';
import AdminSectionWrapper from '../../../../components/helpers/wrappers/AdminSectionWrapper';
import TextField from '../../../../components/helpers/forms/TextField';
import {Customer} from '../../../../model/User';

interface PropType {
    isLoading: boolean;
    customer?: Customer;
}

function CustomerSection(props: PropType): ReactElement {
        return (
            <AdminSectionWrapper title='Klant gegevens' isLoading={props.isLoading} >
                <TextField label="Telefoonnummer" name="phoneNumber" value={props.customer?.phoneNumber || ''}
                           disabled required/>
            </AdminSectionWrapper>
    );
}

export default CustomerSection;