import React, {ReactElement} from 'react';
import UserInfo from '../components/secretariat/booking/UserInfo';
import SchemeSelector from '../components/secretariat/booking/SchemeSelector';
import ContentWrapper from '../components/helpers/wrappers/ContentWrapper';
import ExtraServicesSelector from '../components/secretariat/booking/ExtraServicesSelector';
import TechnicianSelector from '../components/secretariat/booking/TechnicianSelector';
import MaxTravelTime from '../components/secretariat/booking/MaxTravelTime';
import Calendar from '../components/secretariat/calendar/Calendar';
import Button from '../components/helpers/forms/Button';
import CardLayout from '../components/helpers/wrappers/CardLayout';
import VisitingAddressInfo from '../components/secretariat/booking/VisitingAddressInfo';
import {useAppDispatch, useAppSelector} from '../store/hooks';
import {
    selectAddressInfoEntered,
    selectDateAndSlotInfoEntered,
    selectMaxTravelTime,
    selectSchemeChoice,
    selectEfficientTechnicians,
    selectInefficientTechnician,
    selectUserInfoEntered,
    BookingMode,
    selectCustomer,
    selectSlot,
    selectVisitingAddress,
    selectEfficiencyTypeWithTechnicianIds, resetState, selectDate, selectExtraServiceChoices
} from '../store/reducers/SecretariatBookingSlice';
import Overview from '../components/secretariat/booking/Overview';
import BookingModeSelector from '../components/secretariat/booking/BookingModeSelector';
import {followPost} from '../api/HelperFunctions';
import {useMutation} from '@tanstack/react-query';
import {fetchRootLinks} from '../api/Root';

function Booking(): ReactElement {
    const dispatch = useAppDispatch();

    const userInfoEntered = useAppSelector(selectUserInfoEntered);
    const addressInfoEntered = useAppSelector(selectAddressInfoEntered);
    const selectedScheme = useAppSelector(selectSchemeChoice);
    const selectedTechnicians = useAppSelector(selectEfficientTechnicians);
    const selectedTechnician = useAppSelector(selectInefficientTechnician);
    const maxTravelTime = useAppSelector(selectMaxTravelTime);
    const dateAndSlotInfoEntered = useAppSelector(selectDateAndSlotInfoEntered);
    const allRequiredDataEntered = userInfoEntered && addressInfoEntered && !!selectedScheme && (selectedTechnicians?.length! > 0 || selectedTechnician) && !!maxTravelTime && dateAndSlotInfoEntered

    const customer = useAppSelector(selectCustomer);
    const date = useAppSelector(selectDate);
    const slot = useAppSelector(selectSlot);
    const visitingAddress = useAppSelector(selectVisitingAddress);
    const extraServices = useAppSelector(selectExtraServiceChoices);
    const efficiencyTypeWithTechnicianIds = useAppSelector(selectEfficiencyTypeWithTechnicianIds);

    const createSecretariatBookingBody: () => object = () => {
        return {
            createCustomer: customer,
            date: date!,
            startTime: slot!.start,
            schemeId: selectedScheme!.id,
            visitingAddress,
            extraServiceIds: extraServices.map(extraService => extraService.id),
            maxTravelTime,
            efficiencyTypeWithTechnicianIds: {
                bookingMode: BookingMode[efficiencyTypeWithTechnicianIds.bookingMode],
                technicianIds: efficiencyTypeWithTechnicianIds.bookingMode === BookingMode.EFFICIENT ? efficiencyTypeWithTechnicianIds.technicians!.map(technician => technician.id) : undefined,
                technicianId: efficiencyTypeWithTechnicianIds.bookingMode === BookingMode.INEFFICIENT ? efficiencyTypeWithTechnicianIds.technician!.id : undefined,
            },
            source: 'SECRETARIAT',
        }
    };

    const rootLinks = fetchRootLinks();
    const createSecretariatBookingMutation = useMutation({
        mutationFn: () => followPost<void>(rootLinks, 'create-secretariat-booking', {body: createSecretariatBookingBody()}),
        onSuccess: () => {
            alert('De boeking is aangemaakt!');
            dispatch(resetState());
        },
    });

    return (
        <CardLayout>
            <ContentWrapper title="Secretariaat" isActive={true}>
                <div className='flex flex-col w-80 sm:w-96 md:w-128 lg:w-160 space-y-6'>
                    <UserInfo/>
                    <VisitingAddressInfo/>
                    <SchemeSelector/>
                    <ExtraServicesSelector/>
                    <BookingModeSelector />
                    <TechnicianSelector/>
                    <MaxTravelTime/>
                    <Calendar/>
                    <Overview/>
                    <div>
                        <Button onClick={() => createSecretariatBookingMutation.mutate()}
                                loading={createSecretariatBookingMutation.isPending}
                                disabled={!allRequiredDataEntered} right>Maak boeking</Button>
                    </div>
                </div>
            </ContentWrapper>
        </CardLayout>
    );
}

export default Booking;