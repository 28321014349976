import React, {ReactElement} from 'react';
import SubTitle from '../../helpers/text/Subtitle';
import Slider from '../../helpers/forms/Slider';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {selectMaxTravelTime, setMaxTravelTime} from '../../../store/reducers/SecretariatBookingSlice';
import StepCompleted from '../StepCompleted';
import {minutesToHoursAndMinutes} from '../../../utils/TextVisualisation';

function MaxTravelTime(): ReactElement {
    const dispatch = useAppDispatch();

    const maxTravelTime = useAppSelector(selectMaxTravelTime);

    return (
        <div>
            <SubTitle>Maximale rijtijd (enkele rit) <StepCompleted complete={!!maxTravelTime} /></SubTitle>
            {/* TODO: deze onchange moet een debounce krijgen!*/}
            <Slider label={'Maximale rijtijd: ' + minutesToHoursAndMinutes(maxTravelTime)}
                    name="maxDrivingTime" onChange={event => dispatch(setMaxTravelTime(Number(event.target.value)))}
                    value={maxTravelTime} labelFunction={minutesToHoursAndMinutes}
                    min={15} max={240} step={15} width='w-full'/>
        </div>
    );
}

export default MaxTravelTime;