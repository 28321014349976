import React, {ReactElement} from 'react';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import SchemeComponent from '../../booking/scheme/SchemeComponent';
import SubTitle from '../../helpers/text/Subtitle';
import {selectSchemeChoice, setSchemeChoice} from '../../../store/reducers/SecretariatBookingSlice';
import StepCompleted from '../StepCompleted';
import {useQuery} from '@tanstack/react-query';
import {fetchRootLinks} from '../../../api/Root';
import {choiceListQueries} from '../../../api/ChoiceLists';
import {SchemeChoice} from '../../../model/Choice';

function SchemeSelector(): ReactElement {
    const dispatch = useAppDispatch();

    const rootLinks = fetchRootLinks();
    const { data: choiceLists } = useQuery(choiceListQueries.fetchChoiceLists(rootLinks));
    const { data: schemeOverview} = useQuery(choiceListQueries.findSecretariatBookingSchemeChoices(choiceLists));

    const schemes = schemeOverview?._embedded.secretariatBookingSchemes || [];

    const onSelectScheme: (scheme: SchemeChoice) => void = (scheme: SchemeChoice) => {
        dispatch(setSchemeChoice(scheme));
    }

    const selectedScheme = useAppSelector(selectSchemeChoice);

    // TODO: wrapper maken voor secretariat sectie met loader
    return (
        <div>
            <SubTitle>Selecteer dienst <StepCompleted complete={!!selectedScheme} /></SubTitle>
            <div className='w-full flex flex-col items-center justify-center'>
                {schemes.map(scheme => <SchemeComponent key={scheme.id} scheme={scheme} checked={scheme === selectedScheme} onSelectScheme={() => onSelectScheme(scheme)} />)}
            </div>
        </div>
    );
}

export default SchemeSelector;