import React, {ReactElement, ReactNode} from 'react';
import CardLoader from '../loaders/CardLoader';
import Button from '../forms/Button';
import {Title} from '../text/Title';

type ContentWrapperPropsType = {
    title: string;
    children: ReactNode;
    isActive: boolean;
    nextButtonDisabled?: boolean;
    onNextButtonClick?: () => void;
    contentLoading?: boolean;
    buttonLoading?: boolean;
}

function ContentWrapper(props: ContentWrapperPropsType): ReactElement | null {
    if (!props.isActive) {
        return null;
    }
    return (
        <div className='p-5 md:p-8 flex flex-col justify-between items-center space-y-5'>
            <Title>{props.title}</Title>
            <div className='h-full flex flex-col items-center justify-center'>
                {props.contentLoading ? <CardLoader/> : props.children}
            </div>
            {props.onNextButtonClick &&
              <div className='w-full'>
                <Button onClick={props.onNextButtonClick!} disabled={props.nextButtonDisabled || props.buttonLoading}
                        loading={props.buttonLoading} right>Ga verder</Button>
              </div>
            }
        </div>
    );
}

export default ContentWrapper;