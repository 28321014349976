import React, {ReactElement} from 'react';
// @ts-ignore
import { ReactComponent as GoogleLogo } from '../../../assets/images/Google-logo.svg';
import {useAppSelector} from '../../../store/hooks';
import {Link} from 'react-router-dom';
import {selectRedirectUrl} from '../../../store/reducers/AuthenticationSlice';
import {hasLink, resolve} from '../../../utils/HateoasFunctions';
import {fetchRootLinks} from '../../../api/Root';

function GoogleLogin(): ReactElement | null {
    const rootLinks = fetchRootLinks();

    if(!hasLink(rootLinks, 'oauth-google')) {
        return null;
    }

    const redirectUrl = window.location.origin + useAppSelector(selectRedirectUrl);

    const oAuth2Endpoint: URL = resolve(rootLinks, 'oauth-google');
    oAuth2Endpoint.searchParams.append('redirect_uri', redirectUrl);

    return (
        <div className="flex items-center justify-center">
            <Link to={oAuth2Endpoint.href} className="px-4 py-2 border flex gap-2 rounded-lg text-slate-700 hover:text-slate-900 border-slate-200 hover:border-slate-400 hover:shadow transition duration-300">
                <GoogleLogo className="w-6 h-6" />
                <span>Aanmelden met Google</span>
            </Link>
        </div>
    );
}

export default GoogleLogin;