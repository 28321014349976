type Filterable = {
    show?: boolean;
    inactive?: boolean;
};

export const visibleItems: <T extends Filterable>(items: Array<T>) => Array<T> = <T extends Filterable>(items: Array<T>) => {
    return items.filter(item => !item.inactive).filter(item => showCheckSucceeds(item));
}

const showCheckSucceeds: <T extends Filterable>(navItemData: T) => boolean = <T extends Filterable>(navItemData: T) => {
    return navItemData.show === undefined || navItemData.show
}
