import React, {ReactElement} from 'react';
import AdminSectionWrapper from '../../../../components/helpers/wrappers/AdminSectionWrapper';
import {Employee} from '../../../../model/User';
import CheckBox from '../../../../components/helpers/forms/CheckBox';
import SectionTitle from '../../../../components/helpers/text/SectionTitle';

interface PropType {
    isLoading: boolean;
    employee?: Employee;
}

function EmployeeSection(props: PropType): ReactElement {
    // TODO: Roles kunnen nog niet aangemaakt worden, dus kunnen we enkel tonen wat toegekend is aan deze werknemer

    return (
        <AdminSectionWrapper title='Werknemer gegevens' isLoading={props.isLoading}>
            <SectionTitle>Rollen</SectionTitle>
            {props.employee?.roles?.map(role => <CheckBox key={role.name} id={role.name} value={role.name}
                                                          title={role.name} onChange={() => {
            }} checked disabled/>)}
        </AdminSectionWrapper>
    );
}

export default EmployeeSection;