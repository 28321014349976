import React, {ChangeEvent, ReactElement} from 'react';
import SubTitle from '../../helpers/text/Subtitle';
import CheckBox from '../../helpers/forms/CheckBox';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {
    addTechnicianChoice,
    BookingMode,
    removeTechnicianChoice,
    selectBookingMode,
    selectEfficientTechnicians,
    selectInefficientTechnician, selectSchemeChoice,
    setTechnicianChoice
} from '../../../store/reducers/SecretariatBookingSlice';
import StepCompleted from '../StepCompleted';
import Radio from '../../helpers/forms/Radio';
import {useQuery} from '@tanstack/react-query';
import {fetchRootLinks} from '../../../api/Root';
import {choiceListQueries} from '../../../api/ChoiceLists';
import {TechnicianChoice} from '../../../model/Choice';

function TechnicianSelector(): ReactElement | null {
    const dispatch = useAppDispatch();

    const selectedScheme = useAppSelector(selectSchemeChoice);

    const rootLinks = fetchRootLinks();
    const { data: choiceLists } = useQuery(choiceListQueries.fetchChoiceLists(rootLinks));
    const { data: technicianChoicesOverview} = useQuery(choiceListQueries.findSecretariatBookingTechniciansChoices(choiceLists));

    const technicians = technicianChoicesOverview?._embedded.secretariatBookingTechnicians.filter(choice => choice.competenceId === selectedScheme?.id) || [];
    const selectedEfficientTechnicians = useAppSelector(selectEfficientTechnicians);
    const selectedInefficientTechnician = useAppSelector(selectInefficientTechnician);
    const selectedBookingMode = useAppSelector(selectBookingMode);

    // TODO: wrapper maken voor secretariat sectie met loader
    if (selectedBookingMode === BookingMode.EFFICIENT) {
        return (
            <div>
                <SubTitle>Techniekers <StepCompleted complete={selectedEfficientTechnicians!.length > 0}/></SubTitle>
                <div className='w-full flex flex-col items-center justify-center'>
                    {technicians.map(technician => <TechnicianCheckboxComponent key={technician.id}
                                                                              technicianChoice={technician}
                                                                              checked={selectedEfficientTechnicians!.includes(technician)}
                                                                              addTechnicianChoice={() => dispatch(addTechnicianChoice(technician))}
                                                                              removeTechnicianChoice={() => dispatch(removeTechnicianChoice(technician))}/>)}
                </div>
            </div>
        );
    } else if (selectedBookingMode === BookingMode.INEFFICIENT) {
        return (
            <div>
                <SubTitle>Technieker <StepCompleted complete={!!selectedInefficientTechnician}/></SubTitle>
                <div className='w-full flex flex-col items-center justify-center'>
                    {technicians.map(technician => <TechnicianRadioComponent key={technician.id} technicianChoice={technician}
                                                                           checked={selectedInefficientTechnician === technician}
                                                                           setTechnicianChoice={() => dispatch(setTechnicianChoice(technician))}/>)}
                </div>
            </div>
        );
    }
    // Should never happen
    return null;
}

type TechnicianCheckboxComponentPropsType = {
    technicianChoice: TechnicianChoice;
    checked: boolean;
    addTechnicianChoice: () => void;
    removeTechnicianChoice: () => void;
}

function TechnicianCheckboxComponent(props: TechnicianCheckboxComponentPropsType): ReactElement {
    const handleCheckChange: (event: ChangeEvent<HTMLInputElement>) => void = (event: ChangeEvent<HTMLInputElement>) => {
        const checked: boolean = event.target.checked;
        if (checked) {
            props.addTechnicianChoice();
        } else {
            props.removeTechnicianChoice();
        }
    }

    return (
        <CheckBox id={props.technicianChoice.id!} value={props.technicianChoice.id!}
                  title={props.technicianChoice.title}
                  description={props.technicianChoice.description}
                  checked={props.checked} onChange={handleCheckChange}/>
    );
}

type TechnicianRadioComponentPropsType = {
    technicianChoice: TechnicianChoice;
    checked: boolean;
    setTechnicianChoice: () => void;
}

function TechnicianRadioComponent(props: TechnicianRadioComponentPropsType): ReactElement {
    return (
        <Radio id={props.technicianChoice.id!} value={props.technicianChoice.id!}
               title={props.technicianChoice.title}
               description={props.technicianChoice.description}
               checked={props.checked} onChange={props.setTechnicianChoice}/>
    );
}

export default TechnicianSelector;