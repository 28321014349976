import React, {ReactElement} from 'react';
import SelectedDaySuggestionSelector from '../../secretariat/calendar/SelectedDaySuggestionSelector';
import DaySelector from '../../secretariat/calendar/DaySelector';
import MonthSelector from '../../secretariat/calendar/MonthSelector';
import SubTitle from '../../helpers/text/Subtitle';
import {useAppSelector} from '../../../store/hooks';
import {
    BookingMode,
    selectDateAndSlotInfoEntered,
    selectEfficiencyTypeWithTechnicianIds,
    selectMaxTravelTime, selectYearMonth,
    selectSchemeChoice,
    selectVisitingAddress,
} from '../../../store/reducers/SecretariatBookingSlice';
import StepCompleted from '../StepCompleted';
import {useQuery} from '@tanstack/react-query';
import {fetchRootLinks} from '../../../api/Root';
import {suggestionQueries} from '../../../api/Suggestions';

function Calendar(): ReactElement {
    const dateAndSlotInfoEntered = useAppSelector(selectDateAndSlotInfoEntered);

    const selectedYearMonth = useAppSelector(selectYearMonth);
    const visitingAddress = useAppSelector(selectVisitingAddress);
    const scheme = useAppSelector(selectSchemeChoice);
    const maxTravelTime = useAppSelector(selectMaxTravelTime);
    const efficiencyTypeWithTechnicianIds = useAppSelector(selectEfficiencyTypeWithTechnicianIds);

    const calculateCustomerSuggestionsBody: () => object = () => {
        return {
            address: visitingAddress,
            schemeId: scheme!.id,
            maxTravelTime,
            efficiencyTypeWithTechnicianIds: {
                bookingMode: BookingMode[efficiencyTypeWithTechnicianIds.bookingMode],
                technicianIds: efficiencyTypeWithTechnicianIds.bookingMode === BookingMode.EFFICIENT ? efficiencyTypeWithTechnicianIds.technicians!.map(technician => technician.id) : undefined,
                technicianId: efficiencyTypeWithTechnicianIds.bookingMode === BookingMode.INEFFICIENT ? efficiencyTypeWithTechnicianIds.technician!.id : undefined,
            },
            source: 'SECRETARIAT',
        };
    };

    const rootLinks = fetchRootLinks();
    const { data: suggestions} = useQuery(suggestionQueries.secretariatSuggestions(rootLinks, visitingAddress, selectedYearMonth, scheme?.id || '', efficiencyTypeWithTechnicianIds, maxTravelTime, calculateCustomerSuggestionsBody));

    // TODO: wrapper maken voor secretariat sectie met loader
    return (
        <div>
            <SubTitle>Datum & Tijd <StepCompleted complete={dateAndSlotInfoEntered} /></SubTitle>
            <div className='inline-flex flex-col items-center justify-between'>
                <MonthSelector/>
                <DaySelector suggestions={suggestions || {}}/>
                <SelectedDaySuggestionSelector suggestions={suggestions || {}}/>
            </div>
        </div>
    );
}

export default Calendar;