import {Location} from 'react-router-dom';
import {API_URL} from '../configuration/axios';

export const ADMIN_PANEL_PREFIX = '/admin';

export const adminPanelApiUrlFromBrowserLink: (location: Location) => string = (location: Location) => {
    return API_URL + location.pathname.slice(ADMIN_PANEL_PREFIX.length) + location.search;
};

export const adminPanelBrowserLinkFromApiUrl: (apiUrl: URL) => string = (apiUrl: URL) => {
    return ADMIN_PANEL_PREFIX + apiUrl.toString().slice(API_URL.length);
};