import React, {Dispatch, ReactElement, useState} from 'react';
import TextField from '../helpers/forms/TextField';
import Button from '../helpers/forms/Button';
import {useSearchParams} from 'react-router-dom';
import {followPost, followPostForm} from '../../api/HelperFunctions';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {fetchRootLinks} from '../../api/Root';

function EmailAndAccessCode(): ReactElement {
    const queryClient = useQueryClient();

    const rootLinks = fetchRootLinks();
    const [searchParams, setSearchParams] = useSearchParams();
    const [email, setEmail]: [string, Dispatch<string>] = useState<string>('');
    const [code, setCode]: [string, Dispatch<string>] = useState<string>('');

    const createAccessCodeMutation = useMutation({
        mutationFn: () => followPost<void>(rootLinks, 'request-access-code', {body: {email}}),
        onSuccess: () => alert('Er werd een bevestigingscode naar het opgegeven email adres verstuurd. Gelieve dit in het bevestigingscode veld in te geven.'),
    });

    const loginMutation = useMutation({
        mutationFn: () => followPostForm<void>(rootLinks, 'login', {body: {username: email, password: code}}),
        onSuccess: () => queryClient.invalidateQueries({queryKey: ['rootLinks']}),
    });

    if (searchParams.has('email') && searchParams.has('accessCode')) {
        setEmail(searchParams.get('email')!);
        setCode(searchParams.get('accessCode')!);

        searchParams.delete('email');
        searchParams.delete('accessCode');
        setSearchParams(searchParams);

        loginMutation.mutate();
    }

    return (
        <div>
            <TextField label="Email" name="email" value={email}
                       onChange={event => setEmail(event.target.value)} required/>
            <Button onClick={() => createAccessCodeMutation.mutate()}
                    loading={createAccessCodeMutation.isPending}
                    disabled={!email}>Verifieer</Button>
            <TextField label="Bevestigingscode" name="code"
                       value={code} onChange={event => setCode(event.target.value)} required/>
            <Button onClick={() => loginMutation.mutate()}
                    loading={loginMutation.isPending}
                    disabled={!email || !code}>Bevestig</Button>
        </div>
    )
}

export default EmailAndAccessCode;