import React, {MutableRefObject, ReactElement, useEffect, useRef, useState} from 'react';
import AdminSectionWrapper from '../../../../components/helpers/wrappers/AdminSectionWrapper';
import {Technician} from '../../../../model/User';
import TextField from '../../../../components/helpers/forms/TextField';
import DropDown from '../../../../components/helpers/forms/DropDown';
import {City} from '../../../../model/City';
import SectionTitle from '../../../../components/helpers/text/SectionTitle';
import CheckBox from '../../../../components/helpers/forms/CheckBox';
import {IconEdit} from '@tabler/icons-react';
import {c} from '../../../../utils/CssFunctions';
import Button from '../../../../components/helpers/forms/Button';
import {fetchRootLinks} from '../../../../api/Root';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {followPatch} from '../../../../api/HelperFunctions';
import {choiceListQueries} from '../../../../api/ChoiceLists';

interface PropType {
    isLoading: boolean;
    technician?: Technician;
}

function TechnicianSection(props: PropType): ReactElement {
    const queryClient = useQueryClient();

    const [competencesEditMode, setCompetencesEditMode] = useState<boolean>(false);
    const [checkedCompetences, setCheckedCompetences] = useState<Array<string>>([]);

    const rootLinks = fetchRootLinks();
    const { data: choiceLists } = useQuery(choiceListQueries.fetchChoiceLists(rootLinks));
    const { data: cityOverview, isLoading: isLoadingCities} = useQuery(choiceListQueries.findCityChoices(choiceLists));
    const { data: schemeOverview, isLoading: isLoadingSchemes} = useQuery(choiceListQueries.findSchemeChoices(choiceLists));

    const cities = cityOverview?._embedded.cities || [];
    const schemes = schemeOverview?._embedded.schemes || [];

    useEffect(() => {
        if (props.technician) {
            setCheckedCompetences(props.technician?.competences.map((scheme => scheme.id)))
        }
    }, [props.technician]);

    const findPossibleCities: () => Array<City> = () => {
        if (!cities) {
            return [];
        }
        return cities.filter(city => city.postalCode === props.technician?.address?.city.postalCode);
    }

    const possibleCities = findPossibleCities();
    const streetReference: MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement>(null);

    const flipCompetenceCheck: (schemeId: string) => void = (schemeId: string) => {
        const checked = checkedCompetences.includes(schemeId);
        if(checked) {
            const index = checkedCompetences.indexOf(schemeId);
            setCheckedCompetences([
                ...checkedCompetences.slice(0, index),
                ...checkedCompetences.slice(index +1)
            ]);
        } else {
            setCheckedCompetences([...checkedCompetences, schemeId]);
        }
    }

    const cancelCompetencesEdit: () => void = () => {
        setCheckedCompetences(props.technician!.competences.map((scheme => scheme.id)));
        setCompetencesEditMode(false);
    }

    const updateCompetencesBody: () => object = () => {
        return {
            schemeIds: checkedCompetences,
        };
    }

    const updateCompetencesMutation = useMutation({
        mutationFn: () => followPatch<Technician>(props.technician!, 'update-competences', {body: updateCompetencesBody()}),
        onSuccess: (data) => {
            queryClient.setQueryData(['technicians', data.id], data);
            setCompetencesEditMode(false);
        },
    });
    
    return (
        <AdminSectionWrapper title='Technieker gegevens' isLoading={props.isLoading} >
            <SectionTitle>Adres</SectionTitle>
            <TextField width='w-1/3 md:w-1/2' label="Postcode" name="postalCode"
                       value={props.technician?.address.city.postalCode.toString() || ''} disabled required/>
            <DropDown width='w-2/3 md:w-1/2' label='Gemeente' name='city'
                      value={props.technician?.address.city.id}
                      selectItem={() => {}}
                      nextFocusReference={streetReference}
                      options={possibleCities.map(city => {
                          return {label: city.name, value: city.id};
                      })} disabled loading={isLoadingCities} required/>
            <TextField width='w-full md:w-1/2' label="Straat" name="street"
                       reference={streetReference}
                       value={props.technician?.address.street || ''}
                       disabled required/>
            <TextField width='w-2/3 md:w-2/6 xl:w-5/12' label="Nummer" name="houseNumber"
                       value={props.technician?.address.houseNumber || ''}
                       disabled required/>
            <TextField width='w-1/3 md:w-1/6 xl:w-1/12' label="Bus" name="box" value={props.technician?.address.box || ''}
                       disabled/>

            <SectionTitle>Google Calendar</SectionTitle>
            <TextField width='w-full xl:w-5/6' label="Google calendar id" name="googleCalendarId"
                       value={props.technician?.googleCalendarId || ''} disabled/>
            <TextField width='w-full xl:w-1/6' label="Maximale rijtijd" name="maxTravelTime"
                       value={props.technician?.maxTravelTime?.toString() || ''} disabled/>

            <SectionTitle>
                Competenties
                <IconEdit className={(c('ml-1.5 cursor-pointer', competencesEditMode ? 'hidden' : ''))} onClick={() => setCompetencesEditMode(true)}/>
            </SectionTitle>
            {(isLoadingSchemes || updateCompetencesMutation.isPending) && <h3>Loading...</h3>}
            {schemes.map(scheme => <CheckBox key={scheme.id} id={scheme.id} value={scheme.id}
                                                          title={scheme.title} description={scheme.description}
                                             onChange={() => flipCompetenceCheck(scheme.id)}
                                             checked={checkedCompetences.includes(scheme.id) || false}
                                             disabled={!competencesEditMode} />)}

            {
                competencesEditMode &&
                    <div className='w-full flex justify-end'>
                      <Button onClick={() => cancelCompetencesEdit()}>Annuleer</Button>
                      <Button onClick={() => updateCompetencesMutation.mutate()}>Opslaan</Button>
                    </div>
            }
        </AdminSectionWrapper>
    );
}

export default TechnicianSection;