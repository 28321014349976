import React, {Dispatch, ReactElement, ReactNode, SetStateAction} from 'react';
import SubTitle from '../text/Subtitle';
import SectionLoader from '../loaders/SectionLoader';
import {c} from '../../../utils/CssFunctions';
import {IconEdit} from '@tabler/icons-react';

type ContentWrapperPropsType = {
    title: string;
    isLoading: boolean;
    editMode?: boolean;
    setEditMode?: Dispatch<SetStateAction<boolean>>;
    children: ReactNode;
}

function AdminSectionWrapper(props: ContentWrapperPropsType): ReactElement {
    return (
        <div className='flex flex-col w-full md:w-11/12 items-center space-y-3'>
            <SubTitle>
                {props.title}
                {props.editMode !== undefined && props.setEditMode &&
                    <IconEdit className={(c('ml-1.5 cursor-pointer', props.editMode ? 'hidden' : ''))} onClick={() => props.setEditMode!(true)}/>
                }
            </SubTitle>
            <div className='w-full'>
                {
                    props.isLoading ?
                    <SectionLoader /> :
                    props.children
                }
            </div>
        </div>
    );
}

export default AdminSectionWrapper;