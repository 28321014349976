import React, {ChangeEvent, ReactElement} from 'react';
import {c} from '../../../utils/CssFunctions';

interface Props {
    label: string;
    labelFunction?: (val: number) => string;
    name: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    width?: string;
    disabled?: boolean;

    value: number;
    min: number;
    max: number;
    step: number;
}

function Slider(props: Props): ReactElement {
    const numberRange: (lower: number, upper: number, step: number) => Array<number> = (lower: number, upper: number, step: number) => {
        return new Array((upper - lower) / step + 1).fill(undefined).map((_, index) => lower + index*step);
    }

    return (
        <div className={c('inline-block pb-6 px-1', props.width || 'w-full', props.disabled ? 'opacity-40' : '')}>
            <label htmlFor={props.name}
                   className="block mb-2 text-sm font-medium text-black">{props.label}</label>
            <input id={props.name} type="range" disabled={props.disabled}
                   value={props.value}
                   min={props.min} max={props.max} step={props.step}
                   className="w-full h-2 bg-riptide accent-riptide-darker rounded-lg appearance-none cursor-pointer"
                   onChange={props.onChange}/>
            <div className="w-full flex justify-between text-xs">
                {numberRange(props.min, props.max, props.step)
                    .map(val => props.labelFunction ? props.labelFunction(val) : val.toString())
                    .map((val, index) => <SliderValueLabelComponent key={index} value={val} />)}
            </div>
        </div>
    );
}

type SliderValueLabelComponentPropsType = {
    value: string;
}

function SliderValueLabelComponent(props: SliderValueLabelComponentPropsType): ReactElement {
    return (
        <div className="w-4 flex flex-col items-center group relative">
            <span className="border-l w-4 h-2.5 absolute left-2" />
            <span className="mt-2.5 text-center group-odd:invisible lg:group-odd:visible">{props.value}</span>
        </div>
    );
}

export default Slider;