import React, {Dispatch, ReactElement, ReactNode, useEffect, useState} from 'react';
import {resolve} from '../../../utils/HateoasFunctions';
import {fetchRootLinks} from '../../../api/Root';
import {useQuery} from '@tanstack/react-query';
import {useLocation, useNavigate} from 'react-router-dom';
import {appendSearchParams} from '../../../api/HelperFunctions';
import {adminPanelApiUrlFromBrowserLink, adminPanelBrowserLinkFromApiUrl} from '../../../utils/AdminPanelUrl';
import AdminPageOverviewWrapper, {
    ElementColumnTemplate,
    ElementRowTemplate
} from '../../../components/helpers/wrappers/AdminPageOverviewWrapper';
import {Employee} from '../../../model/User';
import {employeeQueries} from '../../../api/Employees';

const DEFAULT_SORT = 'userData.email';

function EmployeeOverview(): ReactElement {
    const location = useLocation();
    const navigate = useNavigate();

    const [apiUrl, setApiUrl]: [string | undefined, Dispatch<string>] = useState<string>();

    const rootLinks = fetchRootLinks();
    useEffect(() => {
        // TODO: customers link moet aangeven dat er een sort kan zijn??
        if (!location.search.includes('sort=')) {
            const searchParams = new URLSearchParams({sort: DEFAULT_SORT});
            const apiUrl: URL = resolve(rootLinks, 'employees');
            appendSearchParams(apiUrl, searchParams);

            navigate(adminPanelBrowserLinkFromApiUrl(apiUrl), {replace: true});
        }
    });

    const { data: employeeOverview, isLoading: isLoadingEmployees} = useQuery(employeeQueries.findAllFromUrl(apiUrl));

    useEffect(() => {
        setApiUrl(adminPanelApiUrlFromBrowserLink(location));
    }, [location.search]);

    const overview = {
        pagedOverview: employeeOverview,
        datakey: 'employees',
    };

    // TODO: table loader
    if(isLoadingEmployees) {
        return (
            <h3>Loading...</h3>
        );
    }


    return (
        <AdminPageOverviewWrapper title='Werknemers' overview={overview} elementListTemplate={elementListTemplate} />
    );
}

const elementListTemplate: (employee: Employee) => ReactNode = (employee: Employee) => {
    return (
        <ElementRowTemplate key={employee.id} element={employee} >
            <ElementColumnTemplate label="Email" value={employee.email!} width="w-full md:w-7/12 flex flex-col"/>
            <ElementColumnTemplate label="Voornaam" value={employee.firstName} width="hidden md:flex md:w-2/12 flex-col"/>
            <ElementColumnTemplate label="Achternaam" value={employee.lastName} width="hidden md:flex md:w-3/12 flex-col"/>
        </ElementRowTemplate>
    );
};

export default EmployeeOverview;