import React, {ReactElement} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {c} from '../../../utils/CssFunctions';
import AnimatedUnderline from '../../helpers/wrappers/AnimatedUnderline';
import {ADMIN_PANEL_PREFIX} from '../../../utils/AdminPanelUrl';
import {NavItemDataType} from './NavItemDataType';

type NavItemPropType = {
    item: NavItemDataType;
    collapsed: boolean;
};

function NavItem(props: NavItemPropType): ReactElement {
    return (
        <li className='flex bg-white-darker cursor-pointer'>
            <NavActionItem item={props.item} collapsed={props.collapsed} />
        </li>
    );
}

function NavActionItem(props: NavItemPropType): ReactElement {
    const location = useLocation();
    const isSelected = location.pathname.slice(ADMIN_PANEL_PREFIX.length).slice('/'.length).startsWith(props.item.url || '');

    const Icon = props.item.icon;

    return (
        <Link to={props.item.url!}
              className={c('flex space-x-1 font-semibold py-2.5 px-5 uppercase text-sm decoration-[3px] underline-offset-4 w-full transition-all duration-300', isSelected ? 'text-riptide' : 'text-riptide-darker hover:text-riptide')}>
            <Icon className={c('w-7 h-7', props.collapsed ? '' : 'sm:w-6 sm:h-6')} />
            <AnimatedUnderline colorStyles='riptide' alwaysVisible={isSelected} topLevelClassNames={c('hidden', props.collapsed ? '' : 'sm:block')} >
                {props.item.title}
            </AnimatedUnderline>
        </Link>
    )
}

export default NavItem;