import React, {Dispatch, MutableRefObject, ReactElement, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import ContentWrapper from '../../helpers/wrappers/ContentWrapper';
import TextField from '../../helpers/forms/TextField';
import DropDown from '../../helpers/forms/DropDown';
import {
    completeVisitingAddressInfoStep,
    selectVisitingAddressInfoStep,
    setVisitingAddress
} from '../../../store/reducers/CustomerBookingWizardSlice';
import {Address} from '../../../model/Address';
import {fetchRootLinks} from '../../../api/Root';
import {useQuery} from '@tanstack/react-query';
import {choiceListQueries} from '../../../api/ChoiceLists';
import {CityChoice} from '../../../model/Choice';

function VisitingAddressInfo(): ReactElement {
    const dispatch = useAppDispatch();

    const [postalCode, setPostalCode]: [string, Dispatch<string>] = useState<string>('');
    const [city, setCity]: [CityChoice | undefined, Dispatch<CityChoice | undefined>] = useState<CityChoice | undefined>(undefined);
    const [street, setStreet]: [string, Dispatch<string>] = useState<string>('');
    const [houseNumber, setHouseNumber]: [string, Dispatch<string>] = useState<string>('');
    const [box, setBox]: [string, Dispatch<string>] = useState<string>('');

    const streetReference: MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement>(null);

    const rootLinks = fetchRootLinks();
    const { data: choiceLists } = useQuery(choiceListQueries.fetchChoiceLists(rootLinks));
    const { data: cityOverview, isLoading: isLoadingCities} = useQuery(choiceListQueries.findCityChoices(choiceLists));

    const cities = cityOverview?._embedded.cities || [];

    const visitingAddressInfoStepVisibilityState = useAppSelector(selectVisitingAddressInfoStep);

    const requiredFieldsEntered: () => boolean = () =>
        !!postalCode &&
        !!city &&
        !!street &&
        !!houseNumber;

    const findPossibleCities: () => Array<CityChoice> = () => {
        if (postalCode.length !== 4 || !cities) {
            return [];
        }
        return cities.filter(city => city.postalCode.toString() === postalCode);
    }

    const possibleCities = findPossibleCities();

    const chooseCity: (cityId: string) => void = (cityId: string) => {
        const cities = possibleCities.filter(city => city.id === cityId);
        if (cities.length === 1) {
            setCity(cities[0]);
        } else {
            setCity(undefined);
        }
    }

    const onNextButtonClick: () => void = () => {
        const visitingAddress: Address = {
            city: {
                id: city!.id,
                name: city!.name,
                postalCode: city!.postalCode,
            }!,
            street: street,
            houseNumber: houseNumber,
            box: box,
        }

        dispatch(completeVisitingAddressInfoStep());
        dispatch(setVisitingAddress(visitingAddress));
    }

    return (
        <ContentWrapper title="Bezoekadres" isActive={visitingAddressInfoStepVisibilityState.isActive}
                        nextButtonDisabled={!requiredFieldsEntered()}
                        onNextButtonClick={() => onNextButtonClick()}>
            <div className='w-52 sm:w-64 md:w-80 lg:w-96'>
                <TextField width='w-full md:w-1/3 lg:w-1/4' label="Postcode" name="postalCode"
                           value={postalCode} onChange={event => setPostalCode(event.target.value)}
                           required
                           tooltip="Uw bezoekadres gegevens zijn noodzakelijk om te kunnen controleren wanneer één van onze techniekers zich in uw buurt bevindt"/>
                <DropDown width='w-full md:w-2/3 lg:w-3/4' label='Gemeente' name='city'
                          value={city?.id} selectItem={item => chooseCity(item.value)}
                          nextFocusReference={streetReference}
                          options={possibleCities.map(city => {
                              return {label: city.name, value: city.id};
                          })} disabled={!postalCode} loading={isLoadingCities} required/>
                <TextField width='w-full md:w-7/12' label="Straat" name="street"
                           reference={streetReference}
                           value={street} onChange={event => setStreet(event.target.value)}
                           required/>
                <TextField width='w-1/2 md:w-3/12' label="Nummer" name="houseNumber"
                           value={houseNumber} onChange={event => setHouseNumber(event.target.value)}
                           required/>
                <TextField width='w-1/2 md:w-2/12' label="Bus" name="box" value={box}
                           onChange={event => setBox(event.target.value)}/>
            </div>
        </ContentWrapper>
    );
}

export default VisitingAddressInfo;