import React, {ReactElement, useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import ContentWrapper from '../../helpers/wrappers/ContentWrapper';
import TextField from '../../helpers/forms/TextField';
import {
    completeCustomerInfoStep,
    selectUserInfoStep,
    selectEmail,
    selectFirstName,
    selectCustomerId,
    selectLastName,
    selectPhoneNumber,
    setFirstName,
    setLastName,
    setPhoneNumber,
    setCustomerData
} from '../../../store/reducers/CustomerBookingWizardSlice';
import {followPut} from '../../../api/HelperFunctions';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {Customer} from '../../../model/User';
import {fetchRootLinks} from '../../../api/Root';
import {userQueries} from '../../../api/Users';
import {customerQueries} from '../../../api/Customers';

function UserInfo(): ReactElement {
    const queryClient = useQueryClient();
    const dispatch = useAppDispatch();

    const userInfoStepVisibilityState = useAppSelector(selectUserInfoStep);

    // TODO: eenmaal we user registration hebben, dan gaat er hier enkel nog phoneNumber moeten zijn.
    const id = useAppSelector(selectCustomerId) || '';
    const email = useAppSelector(selectEmail) || '';
    const firstName = useAppSelector(selectFirstName) || '';
    const lastName = useAppSelector(selectLastName) || '';
    const phoneNumber = useAppSelector(selectPhoneNumber) || '';

    const rootLinks = fetchRootLinks();
    const { data: authenticatedUser, isLoading: isLoadingCurrentUser} = useQuery(userQueries.findAuthenticatedUser(rootLinks));
    const { data: customerData, isLoading: isLoadingCustomerData } = useQuery(customerQueries.findFromCurrentUser(authenticatedUser));

    const updateCustomerBody: () => Customer = () => {
        return {
            id,
            email,
            firstName,
            lastName,
            phoneNumber
        }
    }

    const updateCustomerInfoMutation = useMutation({
        mutationFn: () => followPut<Customer>(customerData!, 'update', {body: updateCustomerBody()}),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['current-user']});
            dispatch(completeCustomerInfoStep());
        },
    })

    useEffect(() => {
        if(customerData) {
            dispatch(setCustomerData(customerData));
        }
    }, [customerData]);

    const requiredFieldsEntered: () => boolean = () =>
        !!id &&
        !!email &&
        !!firstName &&
        !!lastName &&
        !!phoneNumber;

    return (
        <ContentWrapper title="Uw informatie" isActive={userInfoStepVisibilityState.isActive}
                        nextButtonDisabled={!requiredFieldsEntered()}
                        onNextButtonClick={() => updateCustomerInfoMutation.mutate()}
                        buttonLoading={updateCustomerInfoMutation.isPending}>
            <div className='space-y-2 w-52 sm:w-64 md:w-80 lg:w-96'>
                    <TextField label="Email" name="email" value={email} disabled required/>
                    <TextField width='w-full md:w-1/2' label="Voornaam" name="firstName"
                               value={firstName} onChange={event => dispatch(setFirstName(event.target.value))}
                               disabled={isLoadingCurrentUser || isLoadingCustomerData} required/>
                    <TextField width='w-full md:w-1/2' label="Achternaam" name="lastName"
                               value={lastName} onChange={event => dispatch(setLastName(event.target.value))}
                               disabled={isLoadingCurrentUser || isLoadingCustomerData} required/>
                    <TextField label="Telefoonnummer" name="phoneNumber" value={phoneNumber}
                               onChange={event => dispatch(setPhoneNumber(event.target.value))}
                               disabled={isLoadingCurrentUser || isLoadingCustomerData} required
                               tooltip="Het telefoonnummer is nodig zodat het mogelijk is om u te contacteren moest er in een uitzonderlijk geval iets tussen de afspraak komen"/>
            </div>
        </ContentWrapper>
    );
}

export default UserInfo;