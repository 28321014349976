import React, {ReactElement, ReactNode} from 'react';
import {c} from '../../../utils/CssFunctions';
import {IconQuestionMark} from '@tabler/icons-react';

type Position = 'top' | 'bottom' | 'left' | 'right';

interface PropType {
    content: ReactNode;
    hoverText?: string;
    position?: Position;
    disabled?: boolean;
    textColor?: string;
}

const DEFAULT_POSITION: Position = 'top';

function Tooltip(props: PropType): ReactElement {
    const chosenPosition: Position = props.position || DEFAULT_POSITION;
    const textColor = props.textColor ? `text-${props.textColor}` : 'text-gray';

    return (
        <div className={c('group relative inline-block')}>
            {props.hoverText ? <p className={textColor}>{props.hoverText}</p> : <IconQuestionMark size={18} className={textColor}/>}
            <div className={c('absolute bg-bayleaf text-white rounded-md hidden opacity-90 py-0.5 px-1 w-max max-w-sm text-center',
                props.disabled ? '' : 'group-hover:flex group:hover items-center z-10',
                chosenPosition === 'top' ? 'flex-col -top-2 -translate-y-full left-1/2 -translate-x-1/2' : '',
                chosenPosition === 'bottom' ? 'flex-col -bottom-2 translate-y-full left-1/2 -translate-x-1/2' : '',
                chosenPosition === 'left' ? 'flex-row -left-3.5 -translate-x-full top-1/2 -translate-y-1/2' : '',
                chosenPosition === 'right' ? 'flex-row -right-3.5 translate-x-full top-1/2 -translate-y-1/2' : '')}
            >
                {props.content}
                <div className={c('absolute text-bayleaf w-0 h-0',
                    chosenPosition === 'top' ? 'bottom-0 border-x-transparent border-l-[8px] border-r-[8px] border-t-[12px] translate-y-2.5' : '',
                    chosenPosition === 'bottom' ? 'top-0 border-x-transparent border-l-[8px] border-r-[8px] border-b-[12px] -translate-y-2.5' : '',
                    chosenPosition === 'left' ? 'right-0 border-y-transparent border-t-[8px] border-b-[8px] border-l-[12px] translate-x-2.5' : '',
                    chosenPosition === 'right' ? 'left-0 border-y-transparent border-t-[8px] border-b-[8px] border-r-[12px] -translate-x-2.5' : '')} />
            </div>
        </div>
    );
}

export default Tooltip;