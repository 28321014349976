import {HasHateoasLinks} from '../types/HateoasLink';
import {followPost} from './HelperFunctions';
import {hasLink} from '../utils/HateoasFunctions';
import {Address} from '../model/Address';
import {CustomMap} from '../types/CustomMap';
import {Slot} from '../model/Slot';
import {EfficiencyTypeWithTechnicianIds} from '../store/reducers/SecretariatBookingSlice';
import {UseQueryOptions} from '@tanstack/react-query/src/types';

export const suggestionQueries = {
    customerSuggestions: (rootLinks: HasHateoasLinks, visitingAddress: Address, yearMonth: string, schemeId: string, bodyFunction: () => object): UseQueryOptions<CustomMap<Array<Slot>>> => ({
        queryKey: ['suggestions', 'customer', visitingAddress, yearMonth, schemeId],
        queryFn: () => followPost<CustomMap<Array<Slot>>>(rootLinks, 'calculate-customer-suggestions', {urlParams: {yearMonth}, body: bodyFunction()}),
        enabled: hasLink(rootLinks, 'calculate-customer-suggestions'),
    }),
    secretariatSuggestions: (rootLinks: HasHateoasLinks, visitingAddress: Address, yearMonth: string, schemeId: string, efficiencyTypeWithTechnicianIds: EfficiencyTypeWithTechnicianIds, maxTravelTime: number, bodyFunction: () => object): UseQueryOptions<CustomMap<Array<Slot>>> => ({
        queryKey: ['suggestions', 'secretariat', yearMonth, visitingAddress, schemeId, efficiencyTypeWithTechnicianIds.bookingMode, efficiencyTypeWithTechnicianIds.technician?.id, efficiencyTypeWithTechnicianIds.technicians?.map(t => t.id), maxTravelTime],
        queryFn: () => followPost<CustomMap<Array<Slot>>>(rootLinks, 'calculate-customer-suggestions', {urlParams: {yearMonth}, body: bodyFunction()}),
        enabled: hasLink(rootLinks, 'calculate-customer-suggestions') && !!visitingAddress.city && !!schemeId && !!(efficiencyTypeWithTechnicianIds.technicians?.length || efficiencyTypeWithTechnicianIds.technician)
    }),
};