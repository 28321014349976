import React, {Dispatch, MutableRefObject, ReactElement, ReactNode, useEffect, useRef, useState} from 'react';
import {resolve} from '../../utils/HateoasFunctions';
import {useQuery} from '@tanstack/react-query';
import {schemeQueries} from '../../api/Schemes';
import NewAdminPageWrapper, {
    CreateElementType, ElementColumnTemplate,
    ElementRowTemplate
} from '../../components/helpers/wrappers/AdminPageOverviewWrapper';
import {Scheme} from '../../model/Scheme';
import TextField from '../../components/helpers/forms/TextField';
import {SecurityClearance} from '../../model/SecurityClearance';
import DropDown, {OptionType} from '../../components/helpers/forms/DropDown';
import {useLocation, useNavigate} from 'react-router-dom';
import {adminPanelApiUrlFromBrowserLink, adminPanelBrowserLinkFromApiUrl} from '../../utils/AdminPanelUrl';
import {fetchRootLinks} from '../../api/Root';
import {appendSearchParams} from '../../api/HelperFunctions';

const DEFAULT_SORT = 'price';

function SchemeOverview(): ReactElement {
    const location = useLocation();
    const navigate = useNavigate();

    const [apiUrl, setApiUrl]: [string | undefined, Dispatch<string>] = useState<string>();

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [hours, setHours] = useState('');
    const [minutes, setMinutes] = useState('');
    const [price, setPrice] = useState('');
    const [maxTravelTime, setMaxTravelTime] = useState('');
    const [securityClearance, setSecurityClearance] = useState(SecurityClearance[SecurityClearance.PUBLIC]);

    const submitReference: MutableRefObject<HTMLButtonElement | null> = useRef<HTMLButtonElement>(null);

    const rootLinks = fetchRootLinks();
    useEffect(() => {
        // TODO: schemes link moet aangeven dat er een sort kan zijn??
        if (!location.search.includes('sort=')) {
            const searchParams = new URLSearchParams({sort: DEFAULT_SORT});
            const apiUrl: URL = resolve(rootLinks, 'schemes');
            appendSearchParams(apiUrl, searchParams);

            navigate(adminPanelBrowserLinkFromApiUrl(apiUrl), {replace: true});
        }
    });

    const { data: schemeOverview, isLoading: isLoadingSchemes} = useQuery(schemeQueries.findAllFromUrl(apiUrl));

    useEffect(() => {
        setApiUrl(adminPanelApiUrlFromBrowserLink(location));
    }, [location.search]);

    const overview = {
        pagedOverview: schemeOverview,
        datakey: 'schemes',
    };

    const createSchemeTemplate: () => ReactNode = () => {
        const securityClearanceOptions: Array<OptionType> = Object.keys(SecurityClearance)
            .filter(key => isNaN(Number(key)))
            .map(securityClearance => {
                return {value: securityClearance, label: securityClearance}
            });

        return (
            <>
                <TextField width='w-full lg:w-1/3' label='Titel' name='title' value={title} required onChange={event => setTitle(event.target.value)} />
                <TextField width='w-full lg:w-2/3' label='Omschrijving' name='description' value={description} required onChange={event => setDescription(event.target.value)} />

                <TextField width='w-full sm:w-1/2 lg:w-1/4' label="Uren" name="hours" value={hours} required onChange={event => setHours(event.target.value)} />
                <TextField width='w-full sm:w-1/2 lg:w-1/4' label="Minuten" name="minutes" value={minutes} required onChange={event => setMinutes(event.target.value)} />
                <TextField width='w-full sm:w-1/2 lg:w-1/4' label="Prijs (in €)" name="price" value={price} required onChange={event => setPrice(event.target.value)} />
                <TextField width='w-full sm:w-1/2 lg:w-1/4' label="Maximale rijtijd" name="maxTravelTime" value={maxTravelTime} onChange={event => setMaxTravelTime(event.target.value)} />

                <DropDown label='Veiligheidsniveau' name='securityClearance' value={securityClearance} options={securityClearanceOptions} required selectItem={item => setSecurityClearance(item.value)} nextFocusReference={submitReference} />
            </>
        )
    }

    const resetForm: () => void = () => {
        setTitle('');
        setDescription('');
        setHours('');
        setMinutes('');
        setPrice('');
        setMaxTravelTime('');
        setSecurityClearance(SecurityClearance[SecurityClearance.PUBLIC]);
    }

    const createElement: CreateElementType = {
        buttonText: 'Voeg dienst toe',
        form: createSchemeTemplate(),
        submitButtonReference: submitReference,
        body: () => {
            return {
                title,
                description,
                hours,
                minutes,
                price,
                securityClearance,
                maxTravelTime,
            };
        },
        resetForm: resetForm,
    }

    // TODO: table loader
    if (isLoadingSchemes) {
        return (
            <h3>Loading...</h3>
        );
    }

    return (
        <NewAdminPageWrapper<Scheme> title='Diensten' overview={overview} elementListTemplate={elementListTemplate} createElement={createElement} />
    )
}

const elementListTemplate: (scheme: Scheme) => ReactNode = (scheme: Scheme) => {
    return (
        <ElementRowTemplate key={scheme.id} element={scheme} >
            <ElementColumnTemplate label="Titel" value={scheme.title} width="w-11/12 md:w-4/12 lg:w-3/12 xl:w-2/12 flex flex-col"/>
            <ElementColumnTemplate label="Omschrijving" value={scheme.description} width="hidden md:flex md:w-7/12 xl:w-6/12 flex-col"/>
            <ElementColumnTemplate label="Duurtijd" value={scheme.duration.hours + 'u ' + scheme.duration.minutes} width="hidden lg:flex lg:w-1/12 flex-col"/>
            <ElementColumnTemplate label="Prijs" value={'€' + scheme.price} width="hidden xl:flex xl:w-1/12 flex-col"/>
            <ElementColumnTemplate label="Status" value={scheme.active ? 'Actief' : 'Inactief'} width="hidden xl:flex xl:w-1/12 flex-col"/>
        </ElementRowTemplate>
    );
};

export default SchemeOverview;