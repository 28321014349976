import React, {ReactElement} from 'react';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import SubTitle from '../../helpers/text/Subtitle';
import {BookingMode, selectBookingMode, setBookingMode} from '../../../store/reducers/SecretariatBookingSlice';
import StepCompleted from '../StepCompleted';
import Toggle from '../../helpers/forms/Toggle';

function SchemeSelector(): ReactElement {
    const dispatch = useAppDispatch();
    const selectedBookingMode = useAppSelector(selectBookingMode);

    const onChangeMode: () => void = () => {
        if (selectedBookingMode === BookingMode.EFFICIENT) {
            dispatch(setBookingMode(BookingMode.INEFFICIENT));
        } else if (selectedBookingMode === BookingMode.INEFFICIENT) {
            dispatch(setBookingMode(BookingMode.EFFICIENT));
        }
    }

    return (
        <div>
            <SubTitle>Selecteer booking modus <StepCompleted complete={!!BookingMode[selectedBookingMode]}/></SubTitle>
            <Toggle onLabel="Efficiënt" offLabel="Inefficiënt" value={selectedBookingMode === BookingMode.EFFICIENT}
                    onChange={() => onChangeMode()}/>
        </div>
    );
}

export default SchemeSelector;