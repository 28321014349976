import React, {ReactElement} from 'react';
import {c} from '../../../utils/CssFunctions';
import {WizardStepType} from '../../../store/reducers/CustomerBookingWizardSlice';

type ConnectorLinePropsType = {
    previousVisibilityState: WizardStepType;
    nextVisibilityState: WizardStepType;
}

function ConnectorLine(props: ConnectorLinePropsType): ReactElement {

    const previousCompleted: () => boolean = () => {
        return props.previousVisibilityState.isCompleted;
    }

    const nextCompletedOrActive: () => boolean = () => {
        return props.nextVisibilityState.isCompleted || props.nextVisibilityState.isActive
    }

    return (
        <div className={c('w-1 h-16 ml-4.5 -my-1', previousCompleted() && nextCompletedOrActive() ? 'bg-riptide-darker' : 'bg-gray-lightest')} />
    );
}

export default ConnectorLine;