import React, {ReactElement} from 'react';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {
    selectDateMoment,
    selectSlot,
    setSlot,
} from '../../../store/reducers/CustomerBookingWizardSlice';
import {c} from '../../../utils/CssFunctions';
import equal from 'lodash/isEqual';
import {slotAsText} from '../../../utils/TextVisualisation';
import {Slot} from '../../../model/Slot';
import {DATE_FORMAT} from '../../../utils/constants/Formatters';
import {CustomMap} from '../../../types/CustomMap';

interface PropType {
    suggestions: CustomMap<Array<Slot>>
}

function SelectedDaySuggestionSelector(props: PropType): ReactElement | null {
    const dispatch = useAppDispatch();

    const selectedDate = useAppSelector(selectDateMoment);
    const selectedSlot: Slot | undefined = useAppSelector(selectSlot);

    if(!selectedDate) {
        return null;
    }

    const renderSlot: (slot: Slot) => ReactElement = (slot: Slot) => {
        const isSelectedSlot = equal(slot, selectedSlot);
        const extraDivStyle = c('cursor-pointer border', isSelectedSlot ? 'bg-riptide-darker border-riptide-darker' : 'bg-bayleaf/10 border-bayleaf/60');
        const extraPStyle = isSelectedSlot ? 'text-white' : 'text-black';

        return (
            <div key={slot.start} className='px-0.5 py-0.5 md:px-1 md:py-1 w-1/2'>
                <div className={c('w-full h-5 sm:h-7 md:h-8 xl:h-10 rounded flex justify-center items-center', extraDivStyle)} onClick={() => dispatch(setSlot(slot))}>
                    <p className={c('text-base', extraPStyle)}>{slotAsText(slot)}</p>
                </div>
            </div>
        );
    }

    const suggestionsForDay = props.suggestions[selectedDate.format(DATE_FORMAT)];

    return (
        <div className='flex w-full items-center flex-col justify-between py-2 sm:py-4 md:py-6'>
            <p className='focus:outline-none text-base font-bold text-black capitalize'>{selectedDate.format('DD MMMM YYYY')}{selectedSlot && ': ' + slotAsText(selectedSlot)}</p>
            <div className='w-full flex flex-wrap items-center justify-start'>
                {suggestionsForDay.map(slot => renderSlot(slot))}
            </div>
        </div>
    );
}

export default SelectedDaySuggestionSelector;