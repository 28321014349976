import React, {ReactElement, ReactNode} from 'react';
import {c} from '../../../utils/CssFunctions';

interface PropType {
    children: ReactNode;
    newTab?: boolean;
    href: string;
    disabled?: boolean;
}

export function ExternalLink(props: PropType): ReactElement {
    return (
        <a className={c('underline text-riptide-darker', props.disabled ? 'pointer-events-none' : '')} target="_blank" rel="noreferrer" href={props.href}>
            {props.children}
        </a>
    );
}