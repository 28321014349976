import React, {ReactElement} from 'react';
import ContentWrapper from '../components/helpers/wrappers/ContentWrapper';
import EmailAndAccessCode from '../components/login/EmailAndAccessCode';
import CardLayout from '../components/helpers/wrappers/CardLayout';
import OAuth2 from '../components/login/OAuth2';
import {useAppSelector} from '../store/hooks';
import {selectRedirectUrl} from '../store/reducers/AuthenticationSlice';
import {Navigate} from 'react-router-dom';
import {ExternalLink} from '../components/helpers/text/ExternalLink';
import {hasLink} from '../utils/HateoasFunctions';
import {isAuthenticated} from '../api/HelperFunctions';
import {fetchRootLinks} from '../api/Root';

function Login(): ReactElement | null {
    const redirectUrl = useAppSelector(selectRedirectUrl);
    const rootLinks = fetchRootLinks();

    if(isAuthenticated()) {
        return <Navigate to={redirectUrl} />
    }

    if(!hasLink(rootLinks, 'login')){
        return null
    }

    const privacyStatementUrl = 'https://afgekeurd.be/privacyverklaring-eu/?cmplz_region_redirect=true&cmplz-region=eu';
    const termsAndConditionsUrl = 'https://afgekeurd.be/algemene-voorwaarden/';

    return (
        <CardLayout>
            <ContentWrapper title="Aanmelden" isActive={true}>
                <p className='text-sm font-medium text-black'>Door aan te melden gaat u akkoord met de <ExternalLink
                    href={privacyStatementUrl}>privacy verklaring</ExternalLink> en met de <ExternalLink
                    href={termsAndConditionsUrl}>algemene voorwaarden</ExternalLink>.</p>
                <br/>
                <EmailAndAccessCode/>
                <OAuth2/>
            </ContentWrapper>
        </CardLayout>
    );
}

export default Login;