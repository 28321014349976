import React, {ReactElement} from 'react';
import Step from './Step';
import {useAppSelector} from '../../../store/hooks';
import {
    selectCalendarStep, selectEmail, selectExtraServiceChoices,
    selectExtraServicesStep, selectDate, selectSchemeChoice,
    selectSchemeStep, selectSlot, selectUserInfoStep, selectVisitingAddress, selectVisitingAddressInfoStep
} from '../../../store/reducers/CustomerBookingWizardSlice';
import {slotAsText} from '../../../utils/TextVisualisation';
import ConnectorLine from './ConnectorLine';
import {IconBuildingCommunity, IconCalendarEvent, IconHome, IconRuler2, IconUserCircle} from '@tabler/icons-react';

function Wizard(): ReactElement {
    const userEmail = useAppSelector(selectEmail);
    const visitingAddress = useAppSelector(selectVisitingAddress);
    const selectedScheme = useAppSelector(selectSchemeChoice);
    const selectedExtraServices = useAppSelector(selectExtraServiceChoices);
    const selectedDate = useAppSelector(selectDate);
    const selectedSlot = useAppSelector(selectSlot);

    const getUserInfoStepInput: () => string = () => {
        if(!userEmail) {
            return '';
        }
        return userEmail;
    }

    const getVisitingAddressIfoStepInput: () => string = () => {
        if(!visitingAddress) {
            return '';
        }
        return `${visitingAddress.street} ${visitingAddress.houseNumber}${visitingAddress.box ? ' ' + visitingAddress.box : ''}, ${visitingAddress.city.postalCode} ${visitingAddress.city.name}`;
    }

    const getSchemeSelectorStepInput: () => string = () => {
        if(!selectedScheme) {
            return '';
        }
        return selectedScheme.title;
    }

    const getExtraServicesSelectorStepInput: () => string = () => {
        if(!selectedExtraServices) {
            return '';
        }
        return selectedExtraServices.map(extraService => extraService.title).sort().toString().replace(',', ', ');
    }

    const getCalendarStepUserInput: () => string = () => {
        if(!selectedDate || !selectedSlot) {
            return '';
        }
        return selectedDate + ' ' + slotAsText(selectedSlot);
    }

    const userInfoStepVisibilityState = useAppSelector(selectUserInfoStep);
    const visitingAddressInfoStepVisibilityState = useAppSelector(selectVisitingAddressInfoStep);
    const schemeSelectorStepVisibilityState = useAppSelector(selectSchemeStep);
    const extraServicesSelectorStepVisibilityState = useAppSelector(selectExtraServicesStep);
    const calendarStepVisibilityState = useAppSelector(selectCalendarStep);

    const userInfoStepUserInput = getUserInfoStepInput();
    const visitingAddressInfoStepUserInput = getVisitingAddressIfoStepInput();
    const schemeSelectorStepUserInput = getSchemeSelectorStepInput();
    const extraServicesSelectorStepUserInput = getExtraServicesSelectorStepInput();
    const calendarStepUserInput = getCalendarStepUserInput();

    return (
            <div className='p-5 md:p-8'>
                <div className='flex flex-col'>
                    <Step visibilityState={userInfoStepVisibilityState} userResponse={userInfoStepUserInput} stepTitle='Uw informatie'><IconUserCircle /></Step>
                    <ConnectorLine previousVisibilityState={userInfoStepVisibilityState} nextVisibilityState={schemeSelectorStepVisibilityState} />

                    <Step visibilityState={visitingAddressInfoStepVisibilityState} userResponse={visitingAddressInfoStepUserInput} stepTitle='Bezoekadres'><IconHome /></Step>
                    <ConnectorLine previousVisibilityState={visitingAddressInfoStepVisibilityState} nextVisibilityState={schemeSelectorStepVisibilityState} />

                    <Step visibilityState={schemeSelectorStepVisibilityState} userResponse={schemeSelectorStepUserInput} stepTitle='Selecteer afspraak'><IconBuildingCommunity /></Step>
                    <ConnectorLine previousVisibilityState={schemeSelectorStepVisibilityState} nextVisibilityState={extraServicesSelectorStepVisibilityState} />

                    <Step visibilityState={extraServicesSelectorStepVisibilityState} userResponse={extraServicesSelectorStepUserInput} stepTitle="Extra's"><IconRuler2 /></Step>
                    <ConnectorLine previousVisibilityState={extraServicesSelectorStepVisibilityState} nextVisibilityState={calendarStepVisibilityState} />

                    <Step visibilityState={calendarStepVisibilityState} userResponse={calendarStepUserInput} stepTitle='Datum & Tijd'><IconCalendarEvent /></Step>
                </div>
            </div>
    );
}

export default Wizard;