import React, {StrictMode} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {store} from './store/Store';
import {Provider} from 'react-redux';
import 'moment/locale/nl-be';
import moment from 'moment';
import './configuration/axios';
import { BrowserRouter } from 'react-router-dom';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';

moment.locale('nl-be');

interface Config {
    REACT_APP_BASE_URL: string;
    REACT_APP_REACT_QUERY_DEVTOOLS_ENABLED: boolean;
}

declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window { config: Config; }
}

const container = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(container);

const queryClient = new QueryClient()

root.render(
    <StrictMode>
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </Provider>
            <ReactQueryDevtools initialIsOpen={window.config.REACT_APP_REACT_QUERY_DEVTOOLS_ENABLED} />
        </QueryClientProvider>
    </StrictMode>
);
