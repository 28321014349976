import React, {ReactElement} from 'react';
import AdminSectionWrapper from '../../../../components/helpers/wrappers/AdminSectionWrapper';
import TextField from '../../../../components/helpers/forms/TextField';
import {User} from '../../../../model/User';

interface PropType {
    isLoading: boolean;
    user?: User;
}

function UserSection(props: PropType): ReactElement {
        return (
            <AdminSectionWrapper title='Algemene gegevens' isLoading={props.isLoading} >
                <TextField width='w-full xl:w-1/3' label="Email" name="email" value={props.user?.email || ''}
                           disabled required/>
                <TextField width='w-full md:w-1/2 xl:w-1/3' label="Voornaam" name="firstName"
                           value={props.user?.firstName || ''}
                           disabled required/>
                <TextField width='w-full md:w-1/2 xl:w-1/3' label="Achternaam" name="lastName"
                           value={props.user?.lastName || ''}
                           disabled required/>
            </AdminSectionWrapper>
    );
}

export default UserSection;