import React, {ReactElement, ReactNode} from 'react';

interface PropType {
    children: ReactNode;
}

function CardLayout(props: PropType): ReactElement {
    return (
        <div className='flex items-center justify-center'>
            <div className='flex shadow-lg rounded-lg py-3 md:py-6 px-2 md:px-4'>
                {props.children}
            </div>
        </div>
    );
}

export default CardLayout;