import {HasHateoasLinks} from '../types/HateoasLink';
import {follow} from './HelperFunctions';
import {hasLink} from '../utils/HateoasFunctions';
import type {UseQueryOptions} from '@tanstack/react-query/src/types';
import {CityChoice, ExtraServiceChoice, SchemeChoice, SecurityClearanceChoice, TechnicianChoice} from '../model/Choice';
import {Overview} from '../types/Overview';

export const choiceListQueries = {
    // Generic
    fetchChoiceLists: (rootLinks: HasHateoasLinks): UseQueryOptions<HasHateoasLinks> => ({
        queryKey: ['choice-lists'],
        queryFn: () => follow<HasHateoasLinks>(rootLinks, 'choice-lists'),
        enabled: hasLink(rootLinks, 'choice-lists'),
    }),
    findCityChoices: (choiceLists?: HasHateoasLinks): UseQueryOptions<Overview<CityChoice>> => ({
        queryKey: ['choice-lists', 'cities'],
        queryFn: () => follow<Overview<CityChoice>>(choiceLists!, 'cities'),
        enabled: !!choiceLists && hasLink(choiceLists, 'cities'),
    }),

    // Customer booking
    findCustomerBookingSchemeChoices: (choiceLists?: HasHateoasLinks): UseQueryOptions<Overview<SchemeChoice>> => ({
        queryKey: ['choice-lists', 'customerBookingSchemes'],
        queryFn: () => follow<Overview<SchemeChoice>>(choiceLists!, 'customerBookingSchemes'),
        enabled: !!choiceLists && hasLink(choiceLists, 'customerBookingSchemes'),
    }),
    findCustomerBookingExtraServiceChoices: (choiceLists?: HasHateoasLinks): UseQueryOptions<Overview<ExtraServiceChoice>> => ({
        queryKey: ['choice-lists', 'customerBookingExtraServices'],
        queryFn: () => follow<Overview<ExtraServiceChoice>>(choiceLists!, 'customerBookingExtraServices'),
        enabled: !!choiceLists && hasLink(choiceLists, 'customerBookingExtraServices'),
    }),

    // Secretariat booking
    findSecretariatBookingSchemeChoices: (choiceLists?: HasHateoasLinks): UseQueryOptions<Overview<SchemeChoice>> => ({
        queryKey: ['choice-lists', 'secretariatBookingSchemes'],
        queryFn: () => follow<Overview<SchemeChoice>>(choiceLists!, 'secretariatBookingSchemes'),
        enabled: !!choiceLists && hasLink(choiceLists, 'secretariatBookingSchemes'),
    }),
    findSecretariatBookingExtraServiceChoices: (choiceLists?: HasHateoasLinks): UseQueryOptions<Overview<ExtraServiceChoice>> => ({
        queryKey: ['choice-lists', 'secretariatBookingExtraServices'],
        queryFn: () => follow<Overview<ExtraServiceChoice>>(choiceLists!, 'secretariatBookingExtraServices'),
        enabled: !!choiceLists && hasLink(choiceLists, 'secretariatBookingExtraServices'),
    }),
    findSecretariatBookingTechniciansChoices: (choiceLists?: HasHateoasLinks): UseQueryOptions<Overview<TechnicianChoice>> => ({
        queryKey: ['choice-lists', 'secretariatBookingTechnicians'],
        queryFn: () => follow<Overview<TechnicianChoice>>(choiceLists!, 'secretariatBookingTechnicians'),
        enabled: !!choiceLists && hasLink(choiceLists, 'secretariatBookingTechnicians'),
    }),

    // Admin panel
    findSchemeChoices: (choiceLists?: HasHateoasLinks): UseQueryOptions<Overview<SchemeChoice>> => ({
        queryKey: ['choice-lists', 'schemes'],
        queryFn: () => follow<Overview<SchemeChoice>>(choiceLists!, 'schemes'),
        enabled: !!choiceLists && hasLink(choiceLists, 'schemes'),
    }),
    findSecurityClearanceChoices: (choiceLists?: HasHateoasLinks): UseQueryOptions<Overview<SecurityClearanceChoice>> => ({
        queryKey: ['choice-lists', 'securityClearances'],
        queryFn: () => follow<Overview<SecurityClearanceChoice>>(choiceLists!, 'securityClearances'),
        enabled: !!choiceLists && hasLink(choiceLists, 'securityClearances'),
    }),
};