import React, {ReactElement, ReactNode} from 'react';
import {WizardStepType} from '../../../store/reducers/CustomerBookingWizardSlice';
import {c} from '../../../utils/CssFunctions';

type StepPropsType = {
    children: ReactNode;
    visibilityState: WizardStepType;
    stepTitle: string;
    userResponse: string;
}

function Step(props: StepPropsType): ReactElement {

    const circleComponent: () => ReactElement = () => {
        return (
          <div className={c('min-w-[2.5rem] w-10 min-h-[2.5rem] h-10 border-4 rounded-full text-lg flex justify-center items-center', props.visibilityState.isCompleted ? 'text-riptide-darker' : 'text-gray-lightest', props.visibilityState.isCompleted || props.visibilityState.isActive ? 'border-riptide-darker': 'border-gray-lightest')}>
              {props.children}
          </div>
        );
    };

    return (
        <div className='flex space-x-2 z-10'>
            {circleComponent()}
            <div className='text-gray hidden sm:block'>
                <p className='text-sm font-semibold whitespace-nowrap overflow-hidden text-ellipsis max-w-[150px] sm:max-w-[180px] md:max-w-[200px] lg:max-w-[230px]'>{props.stepTitle}</p>
                <p className='text-xs font-light whitespace-nowrap overflow-hidden text-ellipsis max-w-[150px] sm:max-w-[180px] md:max-w-[200px] lg:max-w-[230px]'>{props.userResponse}</p>
            </div>
        </div>
    );
}

export default Step;