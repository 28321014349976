import React, {ReactElement} from 'react';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {
    completeSchemeStep,
    finalizeSchemeSelection,
    selectSchemeChoice,
    selectSchemeStep,
    setSchemeChoice
} from '../../../store/reducers/CustomerBookingWizardSlice';
import ContentWrapper from '../../helpers/wrappers/ContentWrapper';
import SchemeComponent from './SchemeComponent';
import {fetchRootLinks} from '../../../api/Root';
import {useQuery} from '@tanstack/react-query';
import {choiceListQueries} from '../../../api/ChoiceLists';

function SchemeSelector(): ReactElement {
    const dispatch = useAppDispatch();

    const rootLinks = fetchRootLinks();
    const { data: choiceLists } = useQuery(choiceListQueries.fetchChoiceLists(rootLinks));
    const { data: schemeOverview, isLoading: isLoadingSchemes} = useQuery(choiceListQueries.findCustomerBookingSchemeChoices(choiceLists));

    const schemes = schemeOverview?._embedded.customerBookingSchemes || [];

    const onNextButtonClick: () => void = () => {
        dispatch(completeSchemeStep());
        dispatch(finalizeSchemeSelection());
    }

    const schemeSelectorStepVisibilityState = useAppSelector(selectSchemeStep);
    const selectedScheme = useAppSelector(selectSchemeChoice);

    return (
        <ContentWrapper title="Selecteer dienst" isActive={schemeSelectorStepVisibilityState.isActive} contentLoading={isLoadingSchemes} nextButtonDisabled={!selectedScheme} onNextButtonClick={() => onNextButtonClick()}>
            {schemes?.map(scheme => <SchemeComponent key={scheme.id} scheme={scheme} checked={scheme === selectedScheme} onSelectScheme={() => dispatch(setSchemeChoice(scheme))} />)}
        </ContentWrapper>
    );
}

export default SchemeSelector;