import React, {ReactElement} from 'react';

type ElementHeaderPropsType = {
    title: string;
};

function ElementHeader(props: ElementHeaderPropsType): ReactElement {

    return (
        <p className="underline text-lg">{props.title}</p>
    );
}

export default ElementHeader;