import React, {ReactElement} from 'react';
import Radio from '../../helpers/forms/Radio';
import {SchemeChoice} from '../../../model/Choice';

type SchemePropsType = {
    scheme: SchemeChoice;
    checked: boolean;
    onSelectScheme: () => void;
}

function SchemeComponent(props: SchemePropsType): ReactElement {
    return (
        <Radio id={props.scheme.id} value={props.scheme.id}
               title={props.scheme.title}
               description={props.scheme.description}
               onChange={props.onSelectScheme} checked={props.checked}/>
    );
}

export default SchemeComponent;